import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { ADD_DNS, EDIT_DNS, LIST_DNS, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL, } from './constant';
import { listDns as listDnsAction, listDnsSuccess, listDnsError, addDnsSuccess, addDnsError, editDnsSuccess, editDnsError, toggleDrawer, toggleDeleteModal } from './action';
import { addDNS, editDNS, listDNS } from 'services/addon';

export function* listDnsSaga(action) {
  try {
    const res = yield call(listDNS, action.payload);
    const dnsData = JSON.parse(res.text);
    // console.log(dnsData);
    yield put(
      listDnsSuccess({
        total: dnsData.dns.total,
        attributes: dnsData.dns.dns.map((item) => ({
          ...item,
          key: item.dnsid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listDnsError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listDnsError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addDnsSaga(action) {
  try {
    const res = yield call(addDNS, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addDnsSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listDnsAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addDnsError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addDnsError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addDnsError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editDnsSaga(action) {
  try {
    const res = yield call(editDNS, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editDnsSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listDnsAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editDnsError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editDnsError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editDnsError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* DnsSaga() {
  yield takeLatest(LIST_DNS, listDnsSaga);
  yield takeEvery(ADD_DNS, addDnsSaga);
  yield takeEvery(EDIT_DNS, editDnsSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
