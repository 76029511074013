import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
// import ConfirmModal from 'components/ConfirmModal';
import { listQuote, toggleDrawer, toggleDeleteModal, deleteQuote } from './action';
import AddQuoteDrawer from 'components/AddManageQoutesDrawer/Index';
import clientReducer, { initialState as clientInitialState } from 'pages/Client/reducer';
import clientSaga from 'pages/Client/saga';
import { QuoteTableConfig } from './QouteTableConfig';

function ManageQoutes() {
  useInjectReducer({ key: 'quote', reducer });
  useInjectSaga({ key: 'quote', saga });
  useInjectReducer({ key: 'client', reducer: clientReducer });
  useInjectSaga({ key: 'client', saga: clientSaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedQuote, setselectedQuote] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const quoteState = useSelector((state) => state.quote || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = QuoteTableConfig(
      handleSearch,
      handleReset,
      // onDelete,
      editUserQuote,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listQuote({ page: 1, limit: 10 }));
  }, []);

  // const onDelete = (item) => {
  //   if (!_.isNil(item)) {
  //     setselectedQuote(item);
  //     dispatch(toggleDeleteModal());
  //     return;
  // };
  // dispatch(deleteQuote(selectedQuote.quote_id));
  // console.log(item);
  // }
  const resetPagination = () => setCurrentPage(1);

  const editUserQuote = (quote) => {
    setselectedQuote(quote);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listQuote(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listQuote({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };
  // const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listQuote({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Manage Quotes"
        extra={
          <AddQuoteDrawer
            initialState={initialState}
            clientInitialState={clientInitialState}
            selectedQuote={selectedQuote}
            resetPagination={resetPagination}
            editUserQuote={editUserQuote}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'MANAGE_QUOTES_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={quoteState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={quoteState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={quoteState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={quoteState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Quote Confirmation'}
        text={'Are you sure you want to delete this quote ?'}
        toggle={quoteState.deleteModal}
        loading={quoteState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default ManageQoutes;
