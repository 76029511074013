
export const ADD_QUOTE = 'ADD_QUOTE';
export const ADD_QUOTE_SUCCESS = 'ADD_QUOTE_SUCCESS';
export const ADD_QUOTE_ERROR = 'ADD_QUOTE_ERROR';

export const EDIT_QUOTE = 'EDIT_QUOTE';
export const EDIT_QUOTE_SUCCESS = 'EDIT_QUOTE_SUCCESS';
export const EDIT_QUOTE_ERROR = 'EDIT_QUOTE_ERROR';

export const LIST_QUOTE = 'LIST_QUOTE';
export const LIST_QUOTE_SUCCESS = 'LIST_QUOTE_SUCCESS';
export const LIST_QUOTE_ERROR = 'LIST_QUOTE_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


