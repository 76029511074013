
import * as Constant from './constant';

export const addFaq = (payload) => {
  return {
    type: Constant.ADD_FAQ,
    payload,
  };
};

export const addFaqSuccess = () => {
  return {
    type: Constant.ADD_FAQ_SUCCESS,
  };
};

export const addFaqError = () => {
  return {
    type: Constant.ADD_FAQ_ERROR,
  };
};

export const editFaq = (payload) => {
  return {
    type: Constant.EDIT_FAQ,
    payload,
  };
};

export const editFaqSuccess = () => {
  return {
    type: Constant.EDIT_FAQ_SUCCESS,
  };
};

export const editFaqError = () => {
  return {
    type: Constant.EDIT_FAQ_ERROR,
  };
};

export const listFaq = (payload) => {
  return {
    type: Constant.LIST_FAQ,
    payload,
  };
};

export const listFaqSuccess = (payload) => {
  return {
    type: Constant.LIST_FAQ_SUCCESS,
    payload,
  };
};

export const listFaqError = () => {
  return {
    type: Constant.LIST_FAQ_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
