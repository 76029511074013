import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
// import ConfirmModal from 'components/ConfirmModal';
import { listTestimonial, toggleDrawer, toggleDeleteModal, deleteQuote } from './action';
import clientReducer, { initialState as clientInitialState } from 'pages/Client/reducer';
import clientSaga from 'pages/Client/saga';
import { TestimonialTableConfig } from './TestimonialTableConfig';
import AddTestimonialDrawer from 'components/AddTestimonialDrawer';

function Testimonials() {
  useInjectReducer({ key: 'testimonial', reducer });
  useInjectSaga({ key: 'testimonial', saga });
  useInjectReducer({ key: 'client', reducer: clientReducer });
  useInjectSaga({ key: 'client', saga: clientSaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedTestimonial, setselectedTestimonial] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const testimonialState = useSelector((state) => state.testimonial || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = TestimonialTableConfig(
      handleSearch,
      handleReset,
      // onDelete,
      editUserTestimonial,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listTestimonial({ page: 1, limit: 10 }));
  }, []);

  // const onDelete = (item) => {
  //   if (!_.isNil(item)) {
  //     setselectedTestimonial(item);
  //     dispatch(toggleDeleteModal());
  //     return;
  // };
  // dispatch(deleteQuote(selectedTestimonial.quote_id));
  // console.log(item);
  // }
  const resetPagination = () => setCurrentPage(1);

  const editUserTestimonial = (quote) => {
    setselectedTestimonial(quote);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listTestimonial(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listTestimonial({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };
  // const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listTestimonial({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Testimonials"
        extra={
          <AddTestimonialDrawer
            initialState={initialState}
            clientInitialState={clientInitialState}
            selectedTestimonial={selectedTestimonial}
            resetPagination={resetPagination}
            editUserTestimonial={editUserTestimonial}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'MANANGE_TESTIMONIAL_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={testimonialState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={testimonialState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={testimonialState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={testimonialState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Quote Confirmation'}
        text={'Are you sure you want to delete this quote ?'}
        toggle={testimonialState.deleteModal}
        loading={testimonialState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default Testimonials;
