export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
export const ADD_ATTRIBUTE_SUCCESS = 'ADD_ATTRIBUTE_SUCCESS';
export const ADD_ATTRIBUTE_ERROR = 'ADD_ATTRIBUTE_ERROR';

export const EDIT_ATTRIBUTE = 'EDIT_ATTRIBUTE';
export const EDIT_ATTRIBUTE_SUCCESS = 'EDIT_ATTRIBUTE_SUCCESS';
export const EDIT_ATTRIBUTE_ERROR = 'EDIT_ATTRIBUTE_ERROR';

export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const DELETE_ATTRIBUTE_SUCCESS = 'DELETE_ATTRIBUTE_SUCCESS';
export const DELETE_ATTRIBUTE_ERROR = 'DELETE_ATTRIBUTE_ERROR';

export const LIST_ATTRIBUTE = 'LIST_ATTRIBUTE';
export const LIST_ATTRIBUTE_SUCCESS = 'LIST_ATTRIBUTE_SUCCESS';
export const LIST_ATTRIBUTE_ERROR = 'LIST_ATTRIBUTE_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
