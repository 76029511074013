
import * as Constant from './constant';

export const addDns = (payload) => {
  return {
    type: Constant.ADD_DNS,
    payload,
  };
};

export const addDnsSuccess = () => {
  return {
    type: Constant.ADD_DNS_SUCCESS,
  };
};

export const addDnsError = () => {
  return {
    type: Constant.ADD_DNS_ERROR,
  };
};

export const editDns = (payload) => {
  return {
    type: Constant.EDIT_DNS,
    payload,
  };
};

export const editDnsSuccess = () => {
  return {
    type: Constant.EDIT_DNS_SUCCESS,
  };
};

export const editDnsError = () => {
  return {
    type: Constant.EDIT_DNS_ERROR,
  };
};

export const listDns = (payload) => {
  return {
    type: Constant.LIST_DNS,
    payload,
  };
};

export const listDnsSuccess = (payload) => {
  return {
    type: Constant.LIST_DNS_SUCCESS,
    payload,
  };
};

export const listDnsError = () => {
  return {
    type: Constant.LIST_DNS_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
