
import { Space, Tag } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const AddonTableConfig = (onDelete, editAddon, responsibilities) => {
  return [
    {
      title: 'ID',
      dataIndex: 'addonid',
      key: 'addonid',
      width: '5%',
    },
    {
      title: 'Addon Name',
      dataIndex: 'addonname',
      key: 'addonname',
      width: '5%',
    },
    {
      title: 'Category',
      dataIndex: 'productcatname',
      key: 'productcatname',
      width: '10%',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (status) => {
        return (
          <Tag color={_.isEqual(status, 1) ? 'green' : 'red'}>
            {_.isEqual(status, 1) ? 'Active' : 'In Active'}
          </Tag>
        );
      },
    },

    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <EyeOutlined style={{ color: '#0D8616', cursor: 'pointer', fontSize: '20px' }} />
              <EditOutlined
                style={{ color: '#0D4B86', cursor: 'pointer', fontSize: '20px' }}
                onClick={() => editAddon(item)}
              />
            </Space>
          </div>
        );
      },
    },
  ];
};