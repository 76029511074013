import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listHowto, addHowto, editHowto } from 'services/howto';
import { ADD_HOWTO, EDIT_HOWTO, LIST_HOWTO, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listHowto as listHowtoAction, listHowtoSuccess, listHowtoError, addHowtoSuccess, addHowtoError, editHowtoSuccess, editHowtoError, toggleDrawer, toggleDeleteModal } from './action';

export function* listHowtoSaga(action) {
  try {
    const res = yield call(listHowto, action.payload);
    const howtoData = JSON.parse(res.text);
    // console.log(howtoData)

    yield put(
      listHowtoSuccess({
        attributes: howtoData.data.howto.map((item) => ({
          ...item,
          key: item.howtoid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listHowtoError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listHowtoError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* addHowtoSaga(action) {
  try {
    const res = yield call(addHowto, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addHowtoSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listHowtoAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addHowtoError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addHowtoError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addHowtoError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editHowtoSaga(action) {
  try {
    const res = yield call(editHowto, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editHowtoSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listHowtoAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editHowtoError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editHowtoError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editHowtoError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* HowtoSaga() {
  yield takeLatest(LIST_HOWTO, listHowtoSaga);
  yield takeEvery(ADD_HOWTO, addHowtoSaga);
  yield takeEvery(EDIT_HOWTO, editHowtoSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
