import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const addOrder = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/order/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};
export const markOrder = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/order/markfunction`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};

export const listOrder = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/order/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit });
};
export const listUpdateOrder = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/order/getupdateorder`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit });
};
export const markPaid = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/order/markfunction`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};
export const updateOrder = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/order/updateorder`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};
export const editOrder = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/order/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};
export const deleteOrder = async (payload) => {
  return await superagent
    .delete(`${BASE_URL}/order/delete`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};
export const cancelOrder = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/order/cancelorder`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};
export const getcancelOrder = async (payload) => {
  return await superagent
    .get(`${BASE_URL}/order/getcancel`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};

