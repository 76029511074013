import React, { memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { addFaq, editFaq } from 'pages/Faq/action';
const { Option } = Select;

function AddFAQDrawer({ initialState, selectedFaq, editUserFaq, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const faqState = useSelector((state) => state.faq || initialState);
  const [form] = Form.useForm();
  // const [defaultFileList, setDefaultFileList] = useState([]);
  // const [progress, setProgress] = useState(0);

  const toggleFaqDrawer = (open = false) => {
    form.resetFields();
    editUserFaq(null);
  };
  if (!_.isNil(selectedFaq)) {
    form.setFieldsValue({
      message: selectedFaq.message,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }

  const handleFaq = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedFaq) {
        const { faqid } = selectedFaq;
        dispatch(editFaq({ faqid, ...values }));
      } else {
        dispatch(addFaq(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleFaqDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Add FAQ
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedFaq ? `Edit FAQ` : `Add FAQ`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleFaqDrawer()}
        visible={faqState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}          >
            <Button onClick={() => toggleFaqDrawer()} loading={faqState.loading} disabled={faqState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleFaq()} loading={false} disabled={faqState.loading} style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              {selectedFaq ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="faqtitle"
                label="Title"
                rules={[{ required: true, message: 'Please enter Title' }]}
              >
                <Input
                  placeholder="Please enter Title"
                  allowClear={true}
                  disabled={faqState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="faqcontent"
                label="Content"
                rules={[{ required: true, message: 'Please enter Content' }]}
              >
                <Input.TextArea
                  placeholder="Please enter Content"
                  allowClear={true}
                  rows={4}
                  disabled={faqState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="isactive" label="Status">
                <Select placeholder="Status" defaultValue={true}>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddFAQDrawer);
