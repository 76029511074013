import React, { useEffect, memo } from 'react';
import { Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeAllClient } from 'pages/Client/action';
import { addOrderCancel } from 'pages/Orders/CancelOrder/action';
import { activeAllOrder } from 'pages/Orders/action';
const { Option } = Select;

function AddOrderCancelDrawer({ initialState, selectedCancelOrder, clientInitialState, orderInitialState, editUserOrdercancel, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const ordercancelState = useSelector((state) => state.ordercancel || initialState);
  const clientState = useSelector((state) => state.client || clientInitialState);
  const orderState = useSelector((state) => state.order || orderInitialState);
  const [form] = Form.useForm();

  const toggleOrderCancelDrawer = (open = false) => {
    form.resetFields();
    editUserOrdercancel(null);
  };

  useEffect(() => {
    if (ordercancelState.drawer) { dispatch(activeAllClient()) }
  }, [clientState.drawer]);

  useEffect(() => {
    if (ordercancelState.drawer) { dispatch(activeAllOrder()) }
  }, [orderState.drawer]);

  const handleOrderCancel = () => {
    form.validateFields().then((values) => {
      dispatch(addOrderCancel(values));
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleOrderCancelDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Cancel Order
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedCancelOrder ? `` : `Submit`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleOrderCancelDrawer()}
        visible={ordercancelState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => toggleOrderCancelDrawer()} loading={ordercancelState.loading} disabled={ordercancelState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleOrderCancel()} loading={false} disabled={ordercancelState.loading} style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              {selectedCancelOrder ? '' : 'Cancel'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={orderState.loading && ordercancelState.loading && clientState.loading}
          active={orderState.loading && ordercancelState.loading && clientState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            {/* <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Order ID"
                  rules={[{ required: false, message: 'Please enter order id' }]}
                >
                  <Input placeholder="Please enter order id" disabled={ordercancelState.loading} />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Select Order"
                  initialValue={selectedCancelOrder?.order_id}
                  rules={[{ required: true, message: 'Please select order' }]}
                >
                  <Select placeholder="Select Order" loading={orderState.loading}>
                    {orderState.activeallOrders.map((cat) => (
                      <Option value={cat.order_id} key={cat.order_id}>
                        ({cat.order_id}) {cat.productname} ({cat.client_name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  initialValue={selectedCancelOrder?.client_id}
                  rules={[{ required: false, message: 'Please select client' }]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddOrderCancelDrawer);
