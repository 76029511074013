export const EDIT_INVOICE_SETTING = 'EDIT_INVOICE_SETTING';
export const EDIT_INVOICE_SETTING_SUCCESS = 'EDIT_INVOICE_SETTING_SUCCESS';
export const EDIT_INVOICE_SETTING_ERROR = 'EDIT_INVOICE_SETTING_ERROR';

export const LIST_INVOICE_SETTING = 'LIST_INVOICE_SETTING';
export const LIST_INVOICE_SETTING_SUCCESS = 'LIST_INVOICE_SETTING_SUCCESS';
export const LIST_INVOICE_SETTING_ERROR = 'LIST_INVOICE_SETTING_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
