import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "assets/logo.svg";
import Dashboard1 from "assets/icons/Shape.svg";
import Dashboard2 from "assets/icons/ic_Dashboard.svg";
import Team from "assets/icons/Icon metro-organization.svg";
import Info from "assets/icons/Icon material-info-outline.svg";
import Settings from "assets/icons/Icon feather-settings.svg";
import Marketing from "assets/icons/marketing.svg";
// import Marketing_Mail from "assets/icons/ppc.svg";
// import MainDashboard from "assets/icons/main_dashboard.svg";
import _ from "lodash";

function Sidenav(props) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={"color"}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={"color"}
      ></path>
    </svg>,
  ];

  const { SubMenu } = Menu;

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]} style={{ background: "#000" }}>
        {_.includes(props.userResponsibilities, "APP_DASHBOARD") ? (
          <Menu.Item key="0">
            <NavLink className="sidenav-back" to="/dashboard">
              <img src={Dashboard1} alt="" />
              <span style={{ paddingLeft: "16px" }} className="label sidenav-text">
                Dashboard
              </span>
            </NavLink>
          </Menu.Item>
        ) : (
          ""
        )}

        {_.includes(props.userResponsibilities, "ACL_OPEN_TAB") ? (
          <SubMenu key="sub1" icon={<img src={Dashboard2} style={{ marginLeft: "-9px" }} alt="" />} title="Team">
            {_.includes(props.userResponsibilities, "USER_OPEN_MANAGE_SUBTAB") ? (
              <Menu.Item key="1">
                <NavLink className="sidenav-back side2" to="/users">
                  <span className="icon" style={{ background: page === "Roles" ? "Roles" : "" }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Users</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "ACL_ROLE_MANAGE_SUBTAB") ? (
              <Menu.Item key="2">
                <NavLink className="sidenav-back side2" to="/role">
                  <span className="icon" style={{ background: page === "Roles" ? "Roles" : "" }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Roles</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "ACL_RESPONSIBILITIES_MANAGE_SUBTAB") ? (
              <Menu.Item key="3">
                <NavLink className="sidenav-back side2" to="/responsibilities">
                  <span className="icon"
                    style={{ background: page === "responsibilities" ? "responsibilities" : "" }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Responsibilities</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "USER_OPEN_TAB") ? (
          <SubMenu key="sub2" icon={<img src={Settings} style={{ marginLeft: "-9px" }} alt="" />} title="Customer Setting">
            {_.includes(props.userResponsibilities, "USER_ORDER_MANAGE_SUBTAB") ? (
              <Menu.Item key="4">
                <NavLink className="sidenav-back side2" to="/orders">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Orders</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "ORDER_SETTING") ? (
              <SubMenu key="sub3" icon={<img src={Settings} style={{ marginLeft: "-9px" }} alt="icon" />} title="Orders Setting">
                {_.includes(props.userResponsibilities, "UPDATE_ORDER_SUBTAB") ? (
                  <Menu.Item key="5">
                    <NavLink className="sidenav-back side2" to="/updateorders">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">Update</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {_.includes(props.userResponsibilities, "CANCEL_ORDER_SUBTAB") ? (
                  <Menu.Item key="6">
                    <NavLink className="sidenav-back side2" to="/cancelorders">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">Cancel</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
              </SubMenu>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "USER_INVOICE_MANAGE_SUBTAB") ? (
              <Menu.Item key="7">
                <NavLink className="sidenav-back" to="/invoices">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Invoices</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "USER_CLIENT_MANAGE_SUBTAB") ? (
              <Menu.Item key="8">
                <NavLink className="sidenav-back" to="/clients">
                  <span className="icon"
                    style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Clients</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "PURCHASED_PRODUCT") ? (
              <SubMenu key="sub4" icon={<img src={Team} style={{ marginLeft: "-9px" }} alt="icon" />} title="Products">
                {_.includes(props.userResponsibilities, "SERVER_DETAILS_SUBTAB") ? (
                  <Menu.Item key="9">
                    <NavLink className="sidenav-back side2" to="/serverdetails">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">Cloud</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {_.includes(props.userResponsibilities, "SHARED_DETAILS_SUBTAB") ? (
                  <Menu.Item key="10">
                    <NavLink className="sidenav-back side2" to="/shareddetial">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">Shared</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {_.includes(props.userResponsibilities, "DEDICATED_PRODUCT_SUBTAB") ? (
                  <Menu.Item key="11">
                    <NavLink className="sidenav-back side2" to="/dedicateddetial">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">Dedicated</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {_.includes(props.userResponsibilities, "PRODUCT_CONFIG_SUBTAB") ? (
                  <Menu.Item key="12">
                    <NavLink className="sidenav-back side2" to="/productconfig">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">Product Configure</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
              </SubMenu>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "PURCHASED_ADDONS") ? (
              <SubMenu key="sub5" icon={<img src={Team} style={{ marginLeft: "-9px" }} alt="icon" />} title="Addons">
                {_.includes(props.userResponsibilities, "ADD_DNS_SUBTAB") ? (
                  <Menu.Item key="13">
                    <NavLink className="sidenav-back side2" to="/adddns">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">DNS</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {_.includes(props.userResponsibilities, "ADD_SMTP_SUBTAB") ? (
                  <Menu.Item key="14">
                    <NavLink className="sidenav-back side2" to="/addsmtp">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">SMTP</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {_.includes(props.userResponsibilities, "ADD_EMAIL_SUBTAB") ? (
                  <Menu.Item key="15">
                    <NavLink className="sidenav-back side2" to="/addemail">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">Email Hosting</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {_.includes(props.userResponsibilities, "ONECLICK_SUBTAB") ? (
                  <Menu.Item key="16">
                    <NavLink className="sidenav-back side2" to="/oneclick">
                      <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "" }}>
                        {tables}
                      </span>
                      <span className="label sidenav-text">One Click</span>
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ""
                )}
              </SubMenu>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "PRODUCT_OPEN_TAB") ? (
          <SubMenu key="sub6" icon={<img src={Team} style={{ marginLeft: "-9px" }} alt="icon" />} title="Products">
            {_.includes(props.userResponsibilities, "PRODUCT_CATEGORY_MANAGE_SUBTAB") ? (
              <Menu.Item key="17">
                <NavLink className="sidenav-back side2" to="/categories">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Categories</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "PRODUCT_PROD_MANAGE_SUBTAB") ? (
              <Menu.Item key="18">
                <NavLink className="sidenav-back side2" to="/products">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Products</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "PRODUCT_ATTRIBUTE_MANAGE_SUBTAB") ? (
              <Menu.Item key="19">
                <NavLink className="sidenav-back side2" to="/attributes">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Attributes</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}

          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "SETTING_OPEN_TAB") ? (
          <SubMenu key="sub7" icon={<img src={Settings} style={{ marginLeft: "-9px" }} alt="icon" />} title="Settings">
            {_.includes(props.userResponsibilities, "SETTING_INVOICE_SUBTAB") ? (
              <Menu.Item key="20">
                <NavLink className="sidenav-back side2" to="/invoicesettings">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Invoice</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "MARKETING_OPEN_TAB") ? (
          <SubMenu key="sub8" icon={<img src={Marketing} style={{ marginLeft: "-9px" }} alt="icon" />} title="Marketing">
            {_.includes(props.userResponsibilities, "PRODUCT_COUPON_MANAGE_SUBTAB") ? (
              <Menu.Item key="21">
                <NavLink className="sidenav-back side2" to="/coupons">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Coupons</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "PRODUCT_PORTFOLIO_MANAGE_SUBTAB") ? (
              <Menu.Item key="22">
                <NavLink className="sidenav-back side2" to="/portfolio">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Portfolio</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "MANANGE_QUOTES_SUBTAB") ? (
              <Menu.Item key="23">
                <NavLink className="sidenav-back side2" to="/manageqoutes">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Client Queries</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "MANANGE_TESTIMONIAL_SUBTAB") ? (
              <Menu.Item key="24">
                <NavLink className="sidenav-back side2" to="/testimonials">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Testimonials</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}{_.includes(props.userResponsibilities, "MANAGE_SATISFACTION_RATE_SUBTAB") ? (
              <Menu.Item key="25">
                <NavLink className="sidenav-back side2" to="/satisfactionrates">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Satisfaction</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}{_.includes(props.userResponsibilities, "CONTACT_SUBTAB") ? (
              <Menu.Item key="26">
                <NavLink className="sidenav-back side2" to="/contact">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Contact Storefront</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}{_.includes(props.userResponsibilities, "MESSAGE_SUBTAB") ? (
              <Menu.Item key="27">
                <NavLink className="sidenav-back side2" to="/message">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Message</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "PROMOTION_SUBTAB") ? (
              <Menu.Item key="28">
                <NavLink className="sidenav-back side2" to="/promotions">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Promotions</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "HOWTO_SUBTAB") ? (
              <Menu.Item key="29">
                <NavLink className="sidenav-back side2" to="/howto">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">How to's</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "FAQ_SUBTAB") ? (
              <Menu.Item key="30">
                <NavLink className="sidenav-back side2" to="/faq">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">FAQ's</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}

          </SubMenu>
        ) : (
          ""
        )}
        {_.includes(props.userResponsibilities, "REPORTS_OPEN_TAB") ? (
          <SubMenu key="sub9" icon={<img src={Info} style={{ marginLeft: "-9px" }} alt="icon" />} title="Reports">
            {_.includes(props.userResponsibilities, "USER_REPORT_SUBTAB") ? (
              <Menu.Item key="31">
                <NavLink className="sidenav-back side2" to="/userreports">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">User Reports</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "SUBSCRIPTION_REPORT_SUBTAB") ? (
              <Menu.Item key="32">
                <NavLink className="sidenav-back side2" to="/subscriptionreports">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Subscription</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "LOG_REPORT_SUBTAB") ? (
              <Menu.Item key="33">
                <NavLink className="sidenav-back side2" to="/incomereports">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Income Reports</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
            {_.includes(props.userResponsibilities, "LOG_REPORT_SUBTAB") ? (
              <Menu.Item key="34">
                <NavLink className="sidenav-back side2" to="/logreports">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Logs Report</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}{_.includes(props.userResponsibilities, "EVENT_LOG_SUBTAB") ? (
              <Menu.Item key="35">
                <NavLink className="sidenav-back side2" to="/eventslog">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Events Log</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}{_.includes(props.userResponsibilities, "NOTIFICATION_SUBTAB") ? (
              <Menu.Item key="36">
                <NavLink className="sidenav-back side2" to="/notificationlog">
                  <span className="icon" style={{ background: page === "SkySoftTech" ? "color" : "", }}>
                    {tables}
                  </span>
                  <span className="label sidenav-text">Notification</span>
                </NavLink>
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
      </Menu >
    </>
  );
}

export default Sidenav;
