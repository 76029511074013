
import * as Constant from './constant';

export const addSmtp = (payload) => {
  return {
    type: Constant.ADD_SMTP,
    payload,
  };
};

export const addSmtpSuccess = () => {
  return {
    type: Constant.ADD_SMTP_SUCCESS,
  };
};

export const addSmtpError = () => {
  return {
    type: Constant.ADD_SMTP_ERROR,
  };
};

export const editSmtp = (payload) => {
  return {
    type: Constant.EDIT_SMTP,
    payload,
  };
};

export const editSmtpSuccess = () => {
  return {
    type: Constant.EDIT_SMTP_SUCCESS,
  };
};

export const editSmtpError = () => {
  return {
    type: Constant.EDIT_SMTP_ERROR,
  };
};

export const listSmtp = (payload) => {
  return {
    type: Constant.LIST_SMTP,
    payload,
  };
};

export const listSmtpSuccess = (payload) => {
  return {
    type: Constant.LIST_SMTP_SUCCESS,
    payload,
  };
};

export const listSmtpError = () => {
  return {
    type: Constant.LIST_SMTP_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
