import React, { memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { addMessage, editMessage } from 'pages/Message/action';
const { Option } = Select;

function AddWelcomeMessageDrawer({ initialState, selectedMessage, editUserMessage, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const messageState = useSelector((state) => state.message || initialState);
  const [form] = Form.useForm();
  // const [defaultFileList, setDefaultFileList] = useState([]);
  // const [progress, setProgress] = useState(0);

  const toggleMessageDrawer = (open = false) => {
    form.resetFields();
    editUserMessage(null);
  };

  if (!_.isNil(selectedMessage)) {
    form.setFieldsValue({
      message: selectedMessage.message,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }

  const handleMessage = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedMessage) {
        const { message_id } = selectedMessage;
        dispatch(editMessage({ message_id, ...values }));
      } else {
        dispatch(addMessage(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleMessageDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Add Message
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedMessage ? `Edit Message` : `Add Message`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleMessageDrawer()}
        visible={messageState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}          >
            <Button onClick={() => toggleMessageDrawer()} loading={messageState.loading} disabled={messageState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleMessage()} loading={false} disabled={messageState.loading} style={{
              backgroundColor: "#00916c", borderColor: "#00916c", color: "white",
            }}>
              {selectedMessage ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="message"
                label="Welcome Message"
                rules={[{ required: true, message: 'Please enter Message' }]}
              >
                <Input.TextArea
                  placeholder="Please enter Message"
                  rows={4}
                  allowClear={true}
                  disabled={messageState.loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="isactive" label="Status">
                <Select placeholder="Status" defaultValue={true}>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddWelcomeMessageDrawer);
