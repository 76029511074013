
export const ADD_ORDER_CANCEL = 'ADD_ORDER_CANCEL';
export const ADD_ORDER_CANCEL_SUCCESS = 'ADD_ORDER_CANCEL_SUCCESS';
export const ADD_ORDER_CANCEL_ERROR = 'ADD_ORDER_CANCEL_ERROR';

export const EDIT_ORDER_CANCEL = 'EDIT_ORDER_CANCEL';
export const EDIT_ORDER_CANCEL_SUCCESS = 'EDIT_ORDER_CANCEL_SUCCESS';
export const EDIT_ORDER_CANCEL_ERROR = 'EDIT_ORDER_CANCEL_ERROR';

export const LIST_ORDER_CANCEL = 'LIST_ORDER_CANCEL';
export const LIST_ORDER_CANCEL_SUCCESS = 'LIST_ORDER_CANCEL_SUCCESS';
export const LIST_ORDER_CANCEL_ERROR = 'LIST_ORDER_CANCEL_ERROR';


export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


