import { EditOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const InvoiceSettingTableConfig = (editInvoiceSetting) => {
  return [
    {
      title: 'Setting Name',
      dataIndex: 'type',
      key: 'type',
      width: '100%',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <EditOutlined
            onClick={() => editInvoiceSetting(item)}
            style={{ cursor: 'pointer', fontSize: '20px' }}
          />
        );
      },
    },
  ];
};
