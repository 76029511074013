import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import AddSatisfactionDrawer from 'components/AddSatisfactionDrawer/Index';
import categorySaga from 'pages/Category/saga';
import categoryReducer, { initialState as categoryInitialState } from 'pages/Category/reducer';
import { listSatisfaction, toggleDrawer } from './action';
import { SatisfactionTableConfig } from './SatisfactionTableConfig';

function SatisfactionRate() {
  useInjectReducer({ key: 'satisfaction', reducer });
  useInjectSaga({ key: 'satisfaction', saga });
  useInjectReducer({ key: 'category', reducer: categoryReducer });
  useInjectSaga({ key: 'category', saga: categorySaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedSatisfaction, setselectedSatisfaction] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const satisfactionState = useSelector((state) => state.satisfaction || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = SatisfactionTableConfig(
      handleSearch,
      handleReset,
      editUserSatisfaction,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listSatisfaction({ page: 1, limit: 10 }));
  }, []);

  const resetPagination = () => setCurrentPage(1);

  const editUserSatisfaction = (satisfaction) => {
    setselectedSatisfaction(satisfaction);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listSatisfaction(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listSatisfaction({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listSatisfaction({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Satisfaction Rates"
        extra={
          <AddSatisfactionDrawer
            initialState={initialState}
            categoryInitialState={categoryInitialState}
            selectedSatisfaction={selectedSatisfaction}
            resetPagination={resetPagination}
            editUserSatisfaction={editUserSatisfaction}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'MANAGE_SATISFACTION_RATE_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={satisfactionState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={satisfactionState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={satisfactionState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={satisfactionState.loading}
          onChange={onPageChange}
        />
      </Card>
    </>
  );
}

export default SatisfactionRate;
