
export const ADD_SATISFACTION = 'ADD_SATISFACTION';
export const ADD_SATISFACTION_SUCCESS = 'ADD_SATISFACTION_SUCCESS';
export const ADD_SATISFACTION_ERROR = 'ADD_SATISFACTION_ERROR';

export const EDIT_SATISFACTION = 'EDIT_SATISFACTION';
export const EDIT_SATISFACTION_SUCCESS = 'EDIT_SATISFACTION_SUCCESS';
export const EDIT_SATISFACTION_ERROR = 'EDIT_SATISFACTION_ERROR';

export const LIST_SATISFACTION = 'LIST_SATISFACTION';
export const LIST_SATISFACTION_SUCCESS = 'LIST_SATISFACTION_SUCCESS';
export const LIST_SATISFACTION_ERROR = 'LIST_SATISFACTION_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


