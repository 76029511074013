
import * as Constant from './constant';

export const addQuote = (payload) => {
  return {
    type: Constant.ADD_QUOTE,
    payload,
  };
};

export const addQuoteSuccess = () => {
  return {
    type: Constant.ADD_QUOTE_SUCCESS,
  };
};

export const addQuoteError = () => {
  return {
    type: Constant.ADD_QUOTE_ERROR,
  };
};

export const editQuote = (payload) => {
  return {
    type: Constant.EDIT_QUOTE,
    payload,
  };
};

export const editQuoteSuccess = () => {
  return {
    type: Constant.EDIT_QUOTE_SUCCESS,
  };
};

export const editQuoteError = () => {
  return {
    type: Constant.EDIT_QUOTE_ERROR,
  };
};

export const listQuote = (payload) => {
  return {
    type: Constant.LIST_QUOTE,
    payload,
  };
};

export const listQuoteSuccess = (payload) => {
  return {
    type: Constant.LIST_QUOTE_SUCCESS,
    payload,
  };
};

export const listQuoteError = () => {
  return {
    type: Constant.LIST_QUOTE_ERROR,
  };
};
export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
