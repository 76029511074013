import React, { useState, useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeAllProducts } from 'pages/Product/action';
import { addOrderupdate, editOrderupdate } from 'pages/UpdateOrder/action';
import { activeAllClient } from 'pages/Client/action';
import { activeAllOrder } from 'pages/Orders/action';
const { Option } = Select;

function AddOrderUpdateDrawer({ initialState, productInitialState, selectedOrderupdate, clientInitialState, orderInitialState, editUserOrderupdate, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const orderupdateState = useSelector((state) => state.orderupdate || initialState);
  const clientState = useSelector((state) => state.client || clientInitialState);
  const productState = useSelector((state) => state.product || productInitialState);
  const orderState = useSelector((state) => state.order || orderInitialState);
  const [form] = Form.useForm();
  console.log(clientState)
  console.log(productState)
  console.log(orderState)
  const toggleOrderupdateDrawer = (open = false) => {
    form.resetFields();
    editUserOrderupdate(null);
  };

  useEffect(() => {
    if (orderupdateState.drawer) { dispatch(activeAllProducts()) }
  }, [productState.drawer]);

  useEffect(() => {
    if (orderupdateState.drawer) { dispatch(activeAllClient()) }
  }, [clientState.drawer]);

  useEffect(() => {
    if (orderupdateState.drawer) { dispatch(activeAllOrder()) }
  }, [orderState.drawer]);

  const handleOrderupdate = () => {
    form.validateFields().then((values) => {
      if (selectedOrderupdate) {
        const { order_product_id } = selectedOrderupdate;
        dispatch(editOrderupdate({ order_product_id, ...values }));
      } else {
        dispatch(addOrderupdate(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleOrderupdateDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Update Order
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedOrderupdate ? `` : `Update`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleOrderupdateDrawer()}
        visible={orderupdateState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}          >
            <Button onClick={() => toggleOrderupdateDrawer()} loading={orderupdateState.loading} disabled={orderupdateState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleOrderupdate()} loading={false} disabled={orderupdateState.loading} style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              {selectedOrderupdate ? '' : 'Update'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={orderState.loading && productState.loading && orderupdateState.loading && clientState.loading}
          active={orderState.loading && productState.loading && orderupdateState.loading && clientState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            {/* <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Order ID"
                  rules={[{ required: false, message: 'Please enter order id' }]}
                >
                  <Input placeholder="Please enter order id" disabled={orderupdateState.loading} />
                </Form.Item>
              </Col>
            </Row> */}

            <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Select Order"
                  initialValue={selectedOrderupdate?.order_id}
                  rules={[{ required: false, message: 'Please select order' }]}
                >
                  <Select placeholder="Select Order" loading={orderState.loading}>
                    {orderState.activeallOrders.map((cat) => (
                      <Option value={cat.order_id} key={cat.order_id}>
                        ({cat.order_id}) {cat.productname} ({cat.client_name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="product_id"
                  label="Select Product"
                  initialValue={selectedOrderupdate?.productid}
                  rules={[{ required: true, message: 'Please select Product' }]}
                >
                  <Select placeholder="Select Product" loading={productState.loading}>
                    {productState.activeallProducts.map((cat) => (
                      <Option value={cat.productid} key={cat.productid}>
                        {cat.productname} ({cat.productid})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  initialValue={selectedOrderupdate?.client_id}
                  rules={[{ required: true, message: 'Please select client' }]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddOrderUpdateDrawer);
