
import * as Constant from './constant';

export const initialState = { totalCount: 0, data: [], dnsAttribute: [], loading: false, error: false, drawer: false, deleteModal: false };

const dnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_DNS:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_DNS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.total,
        data: action.payload.attributes,
        error: false,
      };
    case Constant.LIST_DNS_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };

    case Constant.ADD_DNS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.ADD_DNS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.ADD_DNS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.EDIT_DNS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Constant.EDIT_DNS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case Constant.EDIT_DNS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case Constant.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: !state.drawer,
      };

    case Constant.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !state.deleteModal,
      };
    default:
      return state;
  }
};

export default dnsReducer;
