import { Input, Button, Space, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const NotificationTableConfig = (handleSearch, handleReset) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                backgroundColor: '#32d193',
                borderColor: '#32d193',
                color: 'white',
              }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'Client ID',
      dataIndex: 'client_id',
      key: 'client_id',
      width: '3%',
    },
    {
      title: 'Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: '3%',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '3%',
      ...getColumnSearchProps('last_name'),

    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '5%',
      ...getColumnSearchProps('email'),

    },

    {
      title: 'Creation',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '5%',
      render: (created_at) => {
        return <div>{created_at ? created_at.split('T')[0] : 'N/A'}</div>;
      },
    },
    {
        title: 'Status',
        dataIndex: 'isactive',
        key: 'isactive',
        width: '5%',
        // ...getColumnSearchProps('isactive'),
        render: (status) => {
          return <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'In Active'}</Tag>;
        },
      },
    
  ];
};
