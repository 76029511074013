import { Space, Tag } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const ClientUserTableConfig = ({ editClientUser, onDelete }) => {
  return [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
      width: '10%',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
      width: '10%',
    },
    {
      title: 'Email',
      dataIndex: 'clientemail',
      key: 'clientemail',
      width: '20%',
    },
    {
      title: 'Master',
      dataIndex: 'is_master',
      key: 'is_master',
      width: '3%',
      render: (is_master) => {
        return <Tag color={is_master ? 'green' : 'red'}>{is_master ? 'Yes' : 'No'}</Tag>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '10%',
      render: (is_active) => {
        return <Tag color={is_active ? 'green' : 'red'}>{is_active ? 'Active' : 'In Active'}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <EditOutlined style={{ color: '#0D4B86', cursor: 'pointer', fontSize: '20px' }} onClick={() => editClientUser(item)} />
              <DeleteOutlined style={{ color: '#F52A0E', cursor: 'pointer', fontSize: '20px' }} onClick={() => onDelete(item)} />
            </Space>
          </div>
        );
      },
    },
  ];
};
