import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const LogTableConfig = (handleSearch, handleReset) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                backgroundColor: '#32d193',
                borderColor: '#32d193',
                color: 'white',
              }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'Client ID',
      dataIndex: 'client_id',
      key: 'client_id',
      width: '3%',
    },
    {
      title: 'First Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: '3%',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '3%',
      ...getColumnSearchProps('last_name'),

    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '3%',
      ...getColumnSearchProps('email'),

    },
    {
      title: 'Last Login IP',
      dataIndex: 'last_login_ip',
      key: 'last_login_ip',
      width: '5%',
    },
    {
      title: 'Last Login Date',
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      width: '5%',
      render: (last_login_date) => {
        return <div>{last_login_date ? last_login_date.split('T')[0] : 'N/A'}</div>;
      },
    },
    
  ];
};
