
export const ADD_ONECLICK = 'ADD_ONECLICK';
export const ADD_ONECLICK_SUCCESS = 'ADD_ONECLICK_SUCCESS';
export const ADD_ONECLICK_ERROR = 'ADD_ONECLICK_ERROR';

export const EDIT_ONECLICK = 'EDIT_ONECLICK';
export const EDIT_ONECLICK_SUCCESS = 'EDIT_ONECLICK_SUCCESS';
export const EDIT_ONECLICK_ERROR = 'EDIT_ONECLICK_ERROR';

export const LIST_ONECLICK = 'LIST_ONECLICK';
export const LIST_ONECLICK_SUCCESS = 'LIST_ONECLICK_SUCCESS';
export const LIST_ONECLICK_ERROR = 'LIST_ONECLICK_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


