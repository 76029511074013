import React, { useEffect, memo } from 'react';
import { Form, Button, Drawer, Col, Row, Select, Skeleton, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeAllClient } from 'pages/Client/action';
import { activeCategory } from 'pages/Category/action';
import { addDns, editDns } from 'pages/Addons/AddDNS/action';
import { activeAllOrder } from 'pages/Orders/action';
const { Option } = Select;

function AddAddonDNSDrawer({ initialState, clientInitialState, orderInitialState, categoryInitialState, selectedDns, editUserDns, resetPagination, roleAddResponsibility, }) {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category || categoryInitialState);
  const clientState = useSelector((state) => state.client || clientInitialState);
  const orderState = useSelector((state) => state.order || orderInitialState);
  const dnsState = useSelector((state) => state.dns || initialState);
  const [form] = Form.useForm();
  const toggleDnsDrawer = (open = false) => {
    form.resetFields();
    editUserDns(null);
  };
  if (!_.isNil(selectedDns)) {
    form.setFieldsValue({
      categoryid: selectedDns.categoryid,
      order_id: selectedDns.order_id,
      client_id: selectedDns.client_id,
      isactive: selectedDns.isactive,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }
  useEffect(() => {
    if (dnsState.drawer) { dispatch(activeAllClient()) }
  }, [clientState.drawer]);

  useEffect(() => {
    if (dnsState.drawer) { dispatch(activeCategory()) }
  }, [categoryState.drawer]);

  useEffect(() => {
    if (dnsState.drawer) { dispatch(activeAllOrder()) }
  }, [orderState.drawer]);

  const handleDns = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;
      if (selectedDns) {
        const { dnsid } = selectedDns;
        dispatch(editDns({ dnsid, ...values }));
      } else {
        dispatch(addDns(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleDnsDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Add DNS
        </Button>
      ) : ('')}

      <Drawer
        title={selectedDns ? `Edit` : `Add`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleDnsDrawer()}
        visible={dnsState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => toggleDnsDrawer()} loading={dnsState.loading} disabled={dnsState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleDns()} loading={false} disabled={dnsState.loading} style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              {selectedDns ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={orderState.loading && categoryState.loading && clientState.loading && dnsState.loading}
          active={orderState.loading && categoryState.loading && clientState.loading && dnsState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="client_id"
                  label="Select Client"
                  initialValue={selectedDns?.client_id}
                  rules={[{ required: true, message: 'Please select client' }]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="categoryid"
                  label="Select Category"
                  initialValue={selectedDns?.categoryid}
                  rules={[{ required: true, message: 'Please select category' }]}
                >
                  <Select placeholder="Select Category" loading={categoryState.loading}>
                    {categoryState.activeCategories.map((cat) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Order ID"
                  rules={[{ required: true, message: 'Please enter order id' }]}
                >
                  <Input placeholder="Please enter order id" disabled={dnsState.loading} />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Select Order"
                  initialValue={selectedDns?.order_id}
                  rules={[{ required: true, message: 'Please select order', }]}
                >
                  <Select placeholder="Select Order" loading={orderState.loading}>
                    {orderState.activeallOrders.map((cat) => (
                      <Option value={cat.order_id} key={cat.order_id}>
                        ({cat.order_id}) {cat.productname} ({cat.client_name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="domainname"
                  label="Domain"
                  rules={[{ required: true, message: 'Please enter Domain' }]}
                >
                  <Input placeholder="Please enter domain" disabled={dnsState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddAddonDNSDrawer);
