import * as Constant from './constant';


export const listEvent = (payload) => {
  return {
    type: Constant.LIST_EVENT,
    payload,
  };
};

export const listEventSuccess = (payload) => {
  return {
    type: Constant.LIST_EVENT_SUCCESS,
    payload,
  };
};

export const listEventError = () => {
  return {
    type: Constant.LIST_EVENT_ERROR,
  };
};

