import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { ADD_SHARED, EDIT_SHARED, LIST_SHARED, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listShared as listSharedAction, listSharedSuccess, listSharedError, addSharedSuccess, addSharedError, editSharedSuccess, editSharedError, toggleDrawer, toggleDeleteModal } from './action';
import { addShared, editShared, listShared } from 'services/linode';

export function* listSharedSaga(action) {
  try {
    const res = yield call(listShared, action.payload);
    const sharedData = JSON.parse(res.text);
    // console.log(dnsData);
    yield put(
      listSharedSuccess({
        total: sharedData.shared.total,
        attributes: sharedData.shared.shared.map((item) => ({
          ...item,
          key: item.shared_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listSharedError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listSharedError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addSharedSaga(action) {
  try {
    const res = yield call(addShared, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addSharedSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listSharedAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addSharedError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addSharedError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addSharedError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editSharedSaga(action) {
  try {
    const res = yield call(editShared, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editSharedSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listSharedAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editSharedError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editSharedError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editSharedError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* SharedSaga() {
  yield takeLatest(LIST_SHARED, listSharedSaga);
  yield takeEvery(ADD_SHARED, addSharedSaga);
  yield takeEvery(EDIT_SHARED, editSharedSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
