import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { getOrderDrawerData, addOrder, listOrder, categoryAddon } from 'services/attribute';
import { ADD_ORDER, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL, DRAWER_DATA, CATEGORY_ADDON, LIST_ORDER, ADD_TO_CART, REMOVE_FROM_CART, ADD_ADDON_TO_CART, REMOVE_ADDON_FROM_CART, MARK_ORDER, ACTIVE_ALL_ORDER } from './constant';
import { drawerDataSuccess, drawerDataError, toggleDrawer, toggleDeleteModal, addOrderSuccess, addOrderError, listOrder as listOrderAction, listOrderSuccess, listOrderError, categoryAddonSuccess, categoryAddonError, addToCart, removeFromCart, addAddonToCart, removeAddonFromCart, markOrderSuccess, markOrderError, activeAllOrdersSuccess, activeAllOrdersError, activeAllOrderSuccess, activeAllOrderError, clearCartList } from './action';
import { markOrder } from 'services/order';
import { getActiveOrder } from 'services/client';

export function* listOrderSaga(action) {
  try {
    const res = yield call(listOrder, action.payload);
    const orderData = JSON.parse(res.text);
    // console.log(orderData);
    const { orders, total } = orderData.data;

    yield put(
      listOrderSuccess({
        total,
        orders: orders.map((item) => ({
          ...item,
          key: item.order_id,
        })),
      }),
    );
    // console.log(orderData);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listOrderError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listOrderError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* activeallOrdersSaga(action) {
  try {
    const res = yield call(getActiveOrder);
    const orderData = JSON.parse(res.text);
    console.log(orderData);
    yield put(activeAllOrderSuccess(orderData.data.allorders));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(activeAllOrderError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(activeAllOrderError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
      yield put(activeAllOrderError(err.response.text));
    }
  }
}
export function* addOrderSaga(action) {
  try {
    const res = yield call(addOrder, action.payload);
    const orderData = JSON.parse(res.text);
    yield put(toggleDrawer());
    yield put(addOrderSuccess(orderData.data));
    message.success(orderData.data.message);
    yield put(listOrderAction({ page: 1, limit: 10 }));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addOrderError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(toggleDrawer());
      yield put(addOrderError(err.response.text));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(drawerDataError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* drawerDataSaga(action) {
  try {
    const res = yield call(getOrderDrawerData);
    const drawerData = JSON.parse(res.text);
    // console.log(drawerData);
    yield put(drawerDataSuccess(drawerData.data));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addOrderError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* categoryAddonSaga(action) {
  try {
    const res = yield call(categoryAddon, action.payload);
    const addonData = JSON.parse(res.text);
    // console.log(addonData)
    yield put(categoryAddonSuccess(addonData.data));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(categoryAddonError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* markOrderSaga(action) {
  try {
    const res = yield call(markOrder, action.payload);
    const userData = JSON.parse(res.text);
    // console.log(userData);
    yield put(markOrderSuccess(userData.data));
    yield put(toggleDrawer());
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(markOrderError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(markOrderError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(markOrderError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export function* addToCartSaga(action) {
  put(addToCart(action.payload));
}

export function* removeFromCartSaga(action) {
  put(removeFromCart(action.payload));
}

export function* addAddonToCartSaga(action) {
  put(addAddonToCart(action.payload));
}

export function* removeAddonFromCartSaga(action) {
  put(removeAddonFromCart(action.payload));
}

export function* clearCartListSaga() {
  put(clearCartList());
}

export default function* roleSaga() {
  yield takeEvery(DRAWER_DATA, drawerDataSaga);
  yield takeEvery(ADD_ORDER, addOrderSaga);
  yield takeEvery(MARK_ORDER, markOrderSaga);
  yield takeEvery(LIST_ORDER, listOrderSaga);
  yield takeEvery(CATEGORY_ADDON, categoryAddonSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
  yield takeEvery(ADD_TO_CART, addToCartSaga);
  yield takeEvery(REMOVE_FROM_CART, removeFromCartSaga);
  yield takeEvery(ADD_ADDON_TO_CART, addAddonToCartSaga);
  yield takeEvery(REMOVE_ADDON_FROM_CART, removeAddonFromCartSaga);
  yield takeEvery(ACTIVE_ALL_ORDER, activeallOrdersSaga);
}
