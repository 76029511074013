import React, { useState, useEffect, useCallback } from 'react';
import { Form, message, Row, Col, Select, Input, Button, List, Avatar } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { listProductAttribute } from 'pages/Product/action';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash"
const { Option } = Select;
function AttributeSelectionModal({ selectedProduct, productState, productSelectedAttributes }) {
  const [items, setItems] = useState([]);
  // const [attribute, setAttribute] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    const productFilter = _.omitBy({ productid: selectedProduct?.productid }, _.isNil);
    dispatch(listProductAttribute(productFilter));
  }, []);
  useEffect(() => {
    setItems(productState.productAttribute.filter((ps) => !_.isEqual(ps.optionvalue, 'N/A')));
  }, [productState]);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updItems = reorder(items, result.source.index, result.destination.index);
    setItems(updItems);
    productSelectedAttributes(updItems);
  };
  const removeFromList = (index) => {
    const newList = items.filter((i) => i.attributeid !== items[index].attributeid);
    setItems(newList);
    productSelectedAttributes(newList);
    form.resetFields();
  };
  const addAttributeOption = useCallback(() => {
    form.validateFields().then((values) => {
      const selectedOpt = items.filter((pa) => _.isEqual(pa.attributeid, values.attributeid));
      if (selectedOpt.length > 0) {
        message.error('Attribute Already Selected');
        return;
      }
      const prodOption = productState.productAttribute.filter((pa) => _.isEqual(pa.attributeid, values.attributeid));
      prodOption[0].optionvalue = values.optionvalue;
      setItems([...items, ...prodOption]);
      productSelectedAttributes([...items, ...prodOption]);
      form.resetFields();
    });
  });
  const getItemStyle = (isDragging, draggableStyle) => ({ userSelect: 'none', ...draggableStyle });
  const getListStyle = (isDraggingOver) => ({ background: isDraggingOver ? 'lightblue' : 'white', marginTop: '10px' });

  return (
    <>
      <Form form={form} layout="vertical" initialValues={{ remember: false }}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="attributeid"
              label="Select Product Attribute"
              initialValue={selectedProduct?.productcategoryid}
              rules={[{ required: true, message: 'Please select attribute' }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Search to Select"
                loading={productState.loading}
                disabled={productState.loading}
                optionFilterProp="children"
                size={'middle'}
                // onSelect={onAttributeSelect}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {productState.productAttribute.map((pa) => (
                  <Option value={pa.attributeid} key={pa.key}>
                    {pa.attributename}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="optionvalue"
              label="Option Value"
              rules={[{ required: true, message: 'Please enter Option Value' }]}
            >
              <Input placeholder="Enter Option Value" disabled={productState.loading} />
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={() => addAttributeOption()} loading={productState.loading} disabled={productState.loading} style={{ backgroundColor: "#0069d9", borderColor: "#0069d9", color: "white" }}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <List bordered size="small" itemLayout="vertical">
                {items.map((item, index) => (
                  <Draggable key={item.key} draggableId={item.key.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <List.Item
                          extra={
                            <Button type="danger" onClick={() => removeFromList(index)}>
                              Remove
                            </Button>
                          }
                        >
                          <List.Item.Meta
                            avatar={<Avatar src={require('assets/148x35-crop.png')} />}
                            title={item.attributename}
                            description={item.optionvalue}
                          />
                        </List.Item>
                        {/* <Row>
                        <Col span={1} onClick={() => removeFromList(index)}>
                          <span
                            style={{
                              color: 'black',
                              border: '1px solid black',
                              padding: '10px',
                              borderRadius: '20px',
                              position: 'absolute',
                              right: 0,
                              top: 0,
                            }}
                          >
                            X
                          </span>
                        </Col>
                      </Row> */}
                      </div>
                    )}
                  </Draggable>
                ))}
              </List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default AttributeSelectionModal;
