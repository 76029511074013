import * as Constant from './constant';

export const editInvoiceSetting = (payload) => {
  return {
    type: Constant.EDIT_INVOICE_SETTING,
    payload,
  };
};

export const editInvoiceSettingSuccess = () => {
  return {
    type: Constant.EDIT_INVOICE_SETTING_SUCCESS,
  };
};

export const editInvoiceSettingError = () => {
  return {
    type: Constant.EDIT_INVOICE_SETTING_ERROR,
  };
};

export const listInvoiceSetting = (payload) => {
  return {
    type: Constant.LIST_INVOICE_SETTING,
    payload,
  };
};

export const listInvoiceSettingSuccess = (payload) => {
  return {
    type: Constant.LIST_INVOICE_SETTING_SUCCESS,
    payload,
  };
};

export const listInvoiceSettingError = () => {
  return {
    type: Constant.LIST_INVOICE_SETTING_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
