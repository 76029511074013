import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { LIST_LOG } from './constant';
import { listLog as listLogAction, listLogSuccess, listLogError } from './action';
import { listUserLogs } from 'services/log';

export function* listLogSaga(action) {
  try {
    const res = yield call(listUserLogs, action.payload);
    const logData = JSON.parse(res.text);
    // console.log(logData)
    yield put(
      listLogSuccess({
        total: logData.logs.total,
        logreports: logData.logs.logs.map((item) => ({
          ...item,
          key: item.client_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listLogError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listLogError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export default function* LogSaga() {
  yield takeLatest(LIST_LOG, listLogSaga);
}
