import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { ADD_ORDER_UPDATE, LIST_ORDER_UPDATE, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listOrderupdate as listOrderupdateAction, listOrderupdateSuccess, listOrderupdateError, addOrderupdateSuccess, addOrderupdateError, toggleDrawer, toggleDeleteModal } from './action';
import { listUpdateOrder, updateOrder } from 'services/order';

export function* listOrderupdateSaga(action) {
  try {
    const res = yield call(listUpdateOrder, action.payload);
    const UpdateOrderData = JSON.parse(res.text);
    // console.log(UpdateOrderData)
    yield put(
      listOrderupdateSuccess({
        total: UpdateOrderData.orders.total,
        attributes: UpdateOrderData.orders.orders.map((item) => ({
          ...item,
          key: item.order_product_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listOrderupdateError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listOrderupdateError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addOrderupdateSaga(action) {
  try {
    const res = yield call(updateOrder, action.payload);
    const userData = JSON.parse(res.text);
    // console.log(userData)
    yield put(addOrderupdateSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listOrderupdateAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addOrderupdateError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addOrderupdateError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addOrderupdateError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* OrderupdateSaga() {
  yield takeLatest(LIST_ORDER_UPDATE, listOrderupdateSaga);
  yield takeEvery(ADD_ORDER_UPDATE, addOrderupdateSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
