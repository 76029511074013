import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { LIST_EVENT } from './constant';
import { listEvent as listEventAction, listEventSuccess, listEventError } from './action';
import { listEventLogs } from 'services/log';

export function* listEventSaga(action) {
  try {
    const res = yield call(listEventLogs, action.payload);
    const eventData = JSON.parse(res.text);
    // console.log(eventData);
    yield put(
      listEventSuccess({
        total: eventData.events.total,
        eventreports: eventData.events.events.map((item) => ({
          ...item,
          key: item.event_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listEventError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listEventError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export default function* EventSaga() {
  yield takeLatest(LIST_EVENT, listEventSaga);
}
