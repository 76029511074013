
import * as Constant from './constant';

export const addDedicated = (payload) => {
  return {
    type: Constant.ADD_DEDICATED,
    payload,
  };
};

export const addDedicatedSuccess = () => {
  return {
    type: Constant.ADD_DEDICATED_SUCCESS,
  };
};

export const addDedicatedError = () => {
  return {
    type: Constant.ADD_DEDICATED_ERROR,
  };
};

export const editDedicated = (payload) => {
  return {
    type: Constant.EDIT_DEDICATED,
    payload,
  };
};

export const editDedicatedSuccess = () => {
  return {
    type: Constant.EDIT_DEDICATED_SUCCESS,
  };
};

export const editDedicatedError = () => {
  return {
    type: Constant.EDIT_DEDICATED_ERROR,
  };
};

export const listDedicated = (payload) => {
  return {
    type: Constant.LIST_DEDICATED,
    payload,
  };
};

export const listDedicatedSuccess = (payload) => {
  return {
    type: Constant.LIST_DEDICATED_SUCCESS,
    payload,
  };
};

export const listDedicatedError = () => {
  return {
    type: Constant.LIST_DEDICATED_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
