export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_ERROR = 'ADD_CLIENT_ERROR';

export const EDIT_CLIENT = 'EDIT_CLIENT';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_ERROR = 'EDIT_CLIENT_ERROR';

export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR';

export const LIST_CLIENT = 'LIST_CLIENT';
export const LIST_CLIENT_SUCCESS = 'LIST_CLIENT_SUCCESS';
export const LIST_CLIENT_ERROR = 'LIST_CLIENT_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
export const CLEAR_CLIENT_USER_LIST = 'CLEAR_CLIENT_USER_LIST';

export const LIST_CLIENT_USER = 'LIST_CLIENT_USER';
export const LIST_CLIENT_USER_SUCCESS = 'LIST_CLIENT_USER_SUCCESS';
export const LIST_CLIENT_USER_ERROR = 'LIST_CLIENT_USER_ERROR';

export const ADD_CLIENT_USER = 'ADD_CLIENT_USER';
export const ADD_CLIENT_USER_SUCCESS = 'ADD_CLIENT_USER_SUCCESS';
export const ADD_CLIENT_USER_ERROR = 'ADD_CLIENT_USER_ERROR';

export const ACTIVE_ALL_CLIENT = 'ACTIVE_CLIENT';
export const ACTIVE_ALL_CLIENT_SUCCESS = 'ACTIVE_CLIENT_SUCCESS';
export const ACTIVE_ALL_CLIENT_ERROR = 'ACTIVE_CLIENT_ERROR';

export const EDIT_CLIENT_USER = 'EDIT_CLIENT_USER';
export const EDIT_CLIENT_USER_SUCCESS = 'EDIT_CLIENT_USER_SUCCESS';
export const EDIT_CLIENT_USER_ERROR = 'EDIT_CLIENT_USER_ERROR';

export const DELETE_CLIENT_USER = 'DELETE_CLIENT_USER';
export const DELETE_CLIENT_USER_SUCCESS = 'DELETE_CLIENT_USER_SUCCESS';
export const DELETE_CLIENT_USER_ERROR = 'DELETE_CLIENT_USER_ERROR';
