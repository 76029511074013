import React, { useState, useEffect, memo } from 'react';
import { Table } from 'antd';
import { ClientUserTableConfig } from './ClientUserTableConfig';
import {  useDispatch } from 'react-redux';
import { listClientUser, clearClientUserList, deleteClientUser } from 'pages/Client/action';
import _ from 'lodash';

function ClientUser({ clientState, selectedClient, selectClientUser }) {
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const columns = ClientUserTableConfig({ editClientUser, onDelete });
    setColumns(columns);
    if (selectedClient) {
      dispatch(listClientUser({ client_id: selectedClient.client_id }));
    } else {
      dispatch(clearClientUserList());
    }
  }, []);

  const editClientUser = (user) => {
    selectClientUser(user);
  };

  const onDelete = (user) => {
    dispatch(deleteClientUser(user));
  };

  return (
    <Table
      columns={columns}
      loading={clientState.loading}
      bordered
      size={'small'}
      dataSource={clientState.clientUser}
      style={{ margin: '10px' }}
    />
  );
}

export default memo(ClientUser);
