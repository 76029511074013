import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listDiscount = async () => {
  return await superagent
    .get(`${BASE_URL}/discount/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2);
};

export const addDiscount = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/discount/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editDiscount = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/discount/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
export const deleteDiscount = async (quote_id) => {
  return await superagent
    .delete(`${BASE_URL}/discount/delete`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .send({ quote_id })
    .retry(2);
};
