import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';
import _ from 'lodash';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listProduct = async ({ limit, page, productname, isactive, isfeatured }) => {
  let productApi = superagent
    .get(`${BASE_URL}/product/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });

  if (productname) {
    productApi.query({ productname });
  }
  if (!_.isNil(isactive)) {
    productApi.query({ isactive });
  }
  if (!_.isNil(isfeatured)) {
    productApi.query({ isfeatured });
  }

  return await productApi;
};

export const addProduct = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/product/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editProduct = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/product/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const listAttribute = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/attribute/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};

export const listProductAttribute = async (productId) => {
  return await superagent
    .get(`${BASE_URL}/product/attributes`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ ...productId });
};

export const addAttribute = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/attribute/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editAttribute = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/attribute/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const listClient = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/client/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });
};

export const addClient = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/client/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editClient = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/client/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const listClientUser = async ({ client_id }) => {
  return await superagent
    .get(`${BASE_URL}/client/list/user`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ client_id });
};

export const addClientUser = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/client/create/user`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editClientUser = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/client/edit/user`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const deleteClientUser = async (payload) => {
  return await superagent
    .delete(`${BASE_URL}/client/delete/user`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const getOrderDrawerData = async () => {
  return await superagent
    .get(`${BASE_URL}/miscellaneous/order`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .retry(1);
};

export const addOrder = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/order/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const listOrder = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/order/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });
};

export const listInvoice = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/invoice/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });
};

export const categoryAddon = async (payload) => {
  return await superagent
    .get(`${BASE_URL}/addon/category/${payload.category_id}`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json');
};
