import React, { useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeCategory } from 'pages/Category/action';
import { addDiscount, editDiscount } from 'pages/Coupons/action';
// import generator from 'generate-password';
const { Option } = Select;

function AddCouponDrawer({ initialState, categoryInitialState, selectedDiscount, editUserDiscount, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category || categoryInitialState);
  const discountState = useSelector((state) => state.discount || initialState);
  const [form] = Form.useForm();

  const toggleDiscountDrawer = (open = false) => {
    form.resetFields();
    editUserDiscount(null);
  };
  // const [password, setPassword] = useState('');
  // const [length, setLength] = useState(15);
  // const [isLowerCase, setIsLowerCase] = useState(true);
  // const [isUpperCase, setIsUpperCase] = useState(false);
  // const [isNumbers, setIsNumbers] = useState(false);
  // const [isSymbols, setIsSymbols] = useState(false);

  // const generatePassword = () => {
  //   const pwd = generator.generate({
  //     length: length,
  //     lowercase: isLowerCase,
  //     uppercase: isUpperCase,
  //     numbers: isNumbers,
  //     symbols: isSymbols,
  //   });
  //   setPassword(pwd);
  // };

  // if (!_.isNil(selectedDiscount)) {
  //   form.setFieldsValue({
  //     Discount_name: selectedDiscount.Discount_name,
  //     url: selectedDiscount.url,
  //     productcategoryid: selectedDiscount.productcategoryid,
  //     isenabled: _.isEqual(selectedDiscount.isenabled, 1) ? true : false,
  //     ishomepageport: selectedDiscount?.ishomepageport || false,
  //     isactive: selectedDiscount.isactive,
  //   });
  // } else {
  //   form.setFieldsValue({
  //     isactive: true,
  //   });
  // }
  useEffect(() => {
    if (discountState.drawer) {
      dispatch(activeCategory());
    }
  }, [categoryState.drawer]);
  const handleDiscount = () => {
    form.validateFields().then((values) => {
      values.isactive = _.isNil(values.isactive) || !values.isactive ? false : true;

      if (selectedDiscount) {
        const { discountid } = selectedDiscount;
        dispatch(editDiscount({ discountid, ...values }));
      } else {
        dispatch(addDiscount(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleDiscountDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          Add Discount
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedDiscount ? `Edit Discount` : `Add Discount`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleDiscountDrawer()}
        visible={discountState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => toggleDiscountDrawer()}
              loading={discountState.loading}
              disabled={discountState.loading}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDiscount()}
              loading={false}
              disabled={discountState.loading}
              style={{
                backgroundColor: "#00916c",
                borderColor: "#00916c",
                color: "white",
              }}
            >
              {selectedDiscount ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={categoryState.loading && discountState.loading}
          active={categoryState.loading && discountState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                {/* <Form.Item
                  label="Discount Code"
                  rules={[{ required: false }]}
                >
                  <InputNumber
                    disabled={discountState.loading}
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                  />
                </Form.Item> */}
              </Col>
            </Row>
            {/* <Row>
              <Col span={6}>
                <Form.Item
                  rules={[{ required: false }]}
                >
                  <Checkbox 
                    checked={isLowerCase}
                    onChange={() => setIsLowerCase((val) => !val)}
                  >Lower Case
                    </Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  rules={[{ required: false }]}
                >
                  <Checkbox 
                    checked={isUpperCase}
                    onChange={() => setIsUpperCase((val) => !val)}
                  >Upper Case
                    </Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  rules={[{ required: false }]}
                >
                  <Checkbox 
                    checked={isNumbers}
                    onChange={() => setIsNumbers((val) => !val)}
                  >Numbers
                    </Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  rules={[{ required: false }]}
                >
                  <Checkbox 
                    checked={isSymbols}
                    onChange={() => setIsSymbols((val) => !val)}
                  >Symbols
                    </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Discount Code"
                  rules={[{ required: false, message: 'Please enter discount code' }]}
                >
                  <Input disabled={discountState.loading} value={password} />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  // loading={discountState.loading}
                  value="Generate Password"
                  onClick={generatePassword}
                  // disabled={discountState.loading}
                  // onSubmit={generatePassword}
                  style={{ backgroundColor: '#32d193', borderColor: '#32d193', color: 'white' }}
                >
                  Generate Code
                </Button>
              </Col>
            </Row> */}
            <br />

            <Row>
              <Col span={24}>
                <Form.Item
                  name="category_id"
                  label="Select Category"
                  initialValue={selectedDiscount?.productcategoryid}
                  rules={[
                    {
                      required: false,
                      message: 'Please select category',
                    },
                  ]}
                >
                  <Select placeholder="Select Category" loading={categoryState.loading}>
                    {categoryState.activeCategories.map((cat) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[{ required: false, message: 'Please enter amount' }]}
                >
                  <Input
                    type="number"
                    placeholder="Please enter amount"
                    disabled={discountState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="discount_begins" label="Discount Begins">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="discount_end" label="Discount End">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddCouponDrawer);
