import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import AddAddonDrawer from 'components/AddAddonDrawer/Index';
import { listAddon, toggleDrawer } from './action';
import { AddonTableConfig } from './AddonTableConfig';
import _ from 'lodash';

function Addon() {
  useInjectReducer({ key: 'addon', reducer });
  useInjectSaga({ key: 'addon', saga });
  const [columns, setColumns] = useState([]);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const addonState = useSelector((state) => state.addon || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = AddonTableConfig(onDelete, editAddon, authState.userResponsibilities);
    setColumns(columns);
    dispatch(listAddon({ page: 1, limit: 10 }));
  }, []);

  const onDelete = (item) => {};

  const resetPagination = () => setCurrentPage(1);

  const editAddon = (addon) => {
    setSelectedAddon(addon);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    dispatch(listAddon({ page, limit: pageSize }));
  };
  return (
    <>
      <Card
        title="Manage Addons"
        extra={
          <AddAddonDrawer
            initialState={initialState}
            selectedAddon={selectedAddon}
            resetPagination={resetPagination}
            editAddon={editAddon}
            loading={addonState.loading}
            categories={addonState.categories}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'PRODUCT_ADDON_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={addonState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={addonState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={addonState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={addonState.loading}
          onChange={onPageChange}
        />
      </Card>
    </>
  );
}

export default Addon;
