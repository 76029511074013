import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listInvoiceSettings, editInvoiceSetting } from 'services/invoice';
import { EDIT_INVOICE_SETTING, LIST_INVOICE_SETTING, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listInvoiceSetting as listInvoiceSettingAction, listInvoiceSettingSuccess, editInvoiceSettingSuccess, toggleDrawer, toggleDeleteModal, listInvoiceSettingError, editInvoiceSettingError } from './action';

export function* listInvoiceSettingSaga(action) {
  try {
    const res = yield call(listInvoiceSettings, action.payload);
    const invoicesettingData = JSON.parse(res.text);
    yield put(listInvoiceSettingSuccess(invoicesettingData.data.params));
    // console.log(invoicesettingData.data.params)
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listInvoiceSettingError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listInvoiceSettingError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editInvoiceSettingSaga(action) {
  try {
    const res = yield call(editInvoiceSetting, action.payload);
    const invoiceSettingData = JSON.parse(res.text);
    message.success(invoiceSettingData.data.message);
    yield put(editInvoiceSettingSuccess());
    yield put(toggleDrawer());
    yield put(listInvoiceSettingAction({ paramType: 'Invoice_Setting' }));
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editInvoiceSettingError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editInvoiceSettingError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editInvoiceSettingError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* roleSaga() {
  yield takeLatest(LIST_INVOICE_SETTING, listInvoiceSettingSaga);
  yield takeEvery(EDIT_INVOICE_SETTING, editInvoiceSettingSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
