import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { ADD_PRODUCT_CONF, EDIT_PRODUCT_CONF, LIST_PRODUCT_CONF, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listProductconf as listProductconfAction, listProductconfSuccess, listProductconfError, addProductconfSuccess, addProductconfError, editProductconfSuccess, editProductconfError, toggleDrawer, toggleDeleteModal } from './action';
import { addProductConfig, editProductConfig, listProductConfig } from 'services/addon';
// import { listProductConfig } from 'services/product';

export function* listProductconfSaga(action) {
  try {
    const res = yield call(listProductConfig, action.payload);
    const dnsData = JSON.parse(res.text);
    console.log(dnsData);
    yield put(
      listProductconfSuccess({
        total: dnsData.prodconfig.total,
        attributes: dnsData.prodconfig.prodconfig.map((item) => ({
          ...item,
          key: item.prodconfig_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listProductconfError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listProductconfError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addProductconfSaga(action) {
  try {
    const res = yield call(addProductConfig, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addProductconfSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listProductconfAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addProductconfError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addProductconfError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addProductconfError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editProductconfSaga(action) {
  try {
    const res = yield call(editProductConfig, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editProductconfSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listProductconfAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editProductconfError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editProductconfError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editProductconfError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* ProductconfSaga() {
  yield takeLatest(LIST_PRODUCT_CONF, listProductconfSaga);
  yield takeEvery(ADD_PRODUCT_CONF, addProductconfSaga);
  yield takeEvery(EDIT_PRODUCT_CONF, editProductconfSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
