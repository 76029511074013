
import * as Constant from './constant';

export const addEmail = (payload) => {
  return {
    type: Constant.ADD_EMAIL,
    payload,
  };
};

export const addEmailSuccess = () => {
  return {
    type: Constant.ADD_EMAIL_SUCCESS,
  };
};

export const addEmailError = () => {
  return {
    type: Constant.ADD_EMAIL_ERROR,
  };
};

export const editEmail = (payload) => {
  return {
    type: Constant.EDIT_EMAIL,
    payload,
  };
};

export const editEmailSuccess = () => {
  return {
    type: Constant.EDIT_EMAIL_SUCCESS,
  };
};

export const editEmailError = () => {
  return {
    type: Constant.EDIT_EMAIL_ERROR,
  };
};

export const listEmail = (payload) => {
  return {
    type: Constant.LIST_EMAIL,
    payload,
  };
};

export const listEmailSuccess = (payload) => {
  return {
    type: Constant.LIST_EMAIL_SUCCESS,
    payload,
  };
};

export const listEmailError = () => {
  return {
    type: Constant.LIST_EMAIL_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
