
import * as Constant from './constant';

export const addShared = (payload) => {
  return {
    type: Constant.ADD_SHARED,
    payload,
  };
};

export const addSharedSuccess = () => {
  return {
    type: Constant.ADD_SHARED_SUCCESS,
  };
};

export const addSharedError = () => {
  return {
    type: Constant.ADD_SHARED_ERROR,
  };
};

export const editShared = (payload) => {
  return {
    type: Constant.EDIT_SHARED,
    payload,
  };
};

export const editSharedSuccess = () => {
  return {
    type: Constant.EDIT_SHARED_SUCCESS,
  };
};

export const editSharedError = () => {
  return {
    type: Constant.EDIT_SHARED_ERROR,
  };
};

export const listShared = (payload) => {
  return {
    type: Constant.LIST_SHARED,
    payload,
  };
};

export const listSharedSuccess = (payload) => {
  return {
    type: Constant.LIST_SHARED_SUCCESS,
    payload,
  };
};

export const listSharedError = () => {
  return {
    type: Constant.LIST_SHARED_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
