
import * as Constant from './constant';

export const addOrderupdate = (payload) => {
  return {
    type: Constant.ADD_ORDER_UPDATE,
    payload,
  };
};

export const addOrderupdateSuccess = () => {
  return {
    type: Constant.ADD_ORDER_UPDATE_SUCCESS,
  };
};

export const addOrderupdateError = () => {
  return {
    type: Constant.ADD_ORDER_UPDATE_ERROR,
  };
};

export const editOrderupdate = (payload) => {
  return {
    type: Constant.EDIT_ORDER_UPDATE,
    payload,
  };
};

export const editOrderupdateSuccess = () => {
  return {
    type: Constant.EDIT_ORDER_UPDATE_SUCCESS,
  };
};

export const editOrderupdateError = () => {
  return {
    type: Constant.EDIT_ORDER_UPDATE_ERROR,
  };
};

export const listOrderupdate = (payload) => {
  return {
    type: Constant.LIST_ORDER_UPDATE,
    payload,
  };
};

export const listOrderupdateSuccess = (payload) => {
  return {
    type: Constant.LIST_ORDER_UPDATE_SUCCESS,
    payload,
  };
};

export const listOrderupdateError = () => {
  return {
    type: Constant.LIST_ORDER_UPDATE_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
