import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listSatisfaction, addSatisfaction, editSatisfaction } from 'services/satisfaction';
import { ADD_SATISFACTION, EDIT_SATISFACTION, LIST_SATISFACTION, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listSatisfaction as listSatisfactionAction, listSatisfactionSuccess, listSatisfactionError, addSatisfactionSuccess, addSatisfactionError, editSatisfactionSuccess, editSatisfactionError, toggleDrawer, toggleDeleteModal } from './action';

export function* listSatisfactionSaga(action) {
  try {
    const res = yield call(listSatisfaction, action.payload);
    const satisfactionData = JSON.parse(res.text);
    // console.log(satisfactionData)
    yield put(
      listSatisfactionSuccess({
        total: satisfactionData.satisfactions.total,
        attributes: satisfactionData.satisfactions.satisfactions.map((item) => ({
          ...item,
          key: item.rate_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listSatisfactionError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listSatisfactionError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}



export function* addSatisfactionSaga(action) {
  try {
    const res = yield call(addSatisfaction, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addSatisfactionSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listSatisfactionAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addSatisfactionError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addSatisfactionError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addSatisfactionError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editSatisfactionSaga(action) {
  try {
    const res = yield call(editSatisfaction, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editSatisfactionSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listSatisfactionAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editSatisfactionError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editSatisfactionError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editSatisfactionError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* SatisfactionSaga() {
  yield takeLatest(LIST_SATISFACTION, listSatisfactionSaga);
  yield takeEvery(ADD_SATISFACTION, addSatisfactionSaga);
  yield takeEvery(EDIT_SATISFACTION, editSatisfactionSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
