import * as Constant from './constant';

export const addAddon = (payload) => {
  return {
    type: Constant.ADD_ADDON,
    payload,
  };
};

export const addAddonSuccess = () => {
  return {
    type: Constant.ADD_ADDON_SUCCESS,
  };
};

export const addAddonError = () => {
  return {
    type: Constant.ADD_ADDON_ERROR,
  };
};

export const editAddon = (payload) => {
  return {
    type: Constant.EDIT_ADDON,
    payload,
  };
};

export const editAddonSuccess = () => {
  return {
    type: Constant.EDIT_ADDON_SUCCESS,
  };
};

export const editAddonError = () => {
  return {
    type: Constant.EDIT_ADDON_ERROR,
  };
};

export const listAddon = (payload) => {
  return {
    type: Constant.LIST_ADDON,
    payload,
  };
};

export const listAddonSuccess = (payload) => {
  return {
    type: Constant.LIST_ADDON_SUCCESS,
    payload,
  };
};

export const listAddonError = () => {
  return {
    type: Constant.LIST_ADDON_ERROR,
  };
};

export const listAddonCategory = (payload) => {
  return {
    type: Constant.LIST_ADDON_CATEGORY,
    payload,
  };
};

export const listAddonCategorySuccess = (payload) => {
  return {
    type: Constant.LIST_ADDON_CATEGORY_SUCCESS,
    payload,
  };
};

export const listAddonCategoryError = () => {
  return {
    type: Constant.LIST_ADDON_CATEGORY_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};

export const drawerData = (payload) => {
  return {
    type: Constant.DRAWER_DATA,
    payload,
  };
};

export const drawerDataSuccess = (payload) => {
  return {
    type: Constant.DRAWER_DATA_SUCCESS,
    payload,
  };
};

export const drawerDataError = () => {
  return {
    type: Constant.DRAWER_DATA_ERROR,
  };
};

export const addAddonAttribute = (payload) => {
  return {
    type: Constant.ADD_ADDON_ATTRIBUTE,
    payload,
  };
};

export const removeAddonAttribute = (payload) => {
  return {
    type: Constant.REMOVE_ADDON_ATTRIBUTE,
    payload,
  };
};

export const clearAddonAttribute = () => {
  return {
    type: Constant.CLEAR_ADDON_ATTRIBUTE,
  };
};


