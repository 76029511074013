import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const ContactTableConfig = (handleSearch, handleReset) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                backgroundColor: '#32d193',
                borderColor: '#32d193',
                color: 'white',
              }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'ID',
      dataIndex: 'contactid',
      key: 'contactid',
      width: '2%',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: '5%',
      ...getColumnSearchProps('client_name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '3%',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '3%',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: '5%',
    },
    {
      title: 'Phone No:',
      dataIndex: 'phoneno',
      key: 'phoneno',
      width: '3%',
    },
  ];
};
