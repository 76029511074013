import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { LIST_NOTIFICATION } from './constant';
import { listNotification as listNotificationAction, listNotificationSuccess, listNotificationError } from './action';
import { listNotificationLogs, listUserLogs } from 'services/log';

export function* listNotificationSaga(action) {
  try {
    const res = yield call(listNotificationLogs, action.payload);
    const notificationData = JSON.parse(res.text);
    // console.log(notificationData)
    yield put(
      listNotificationSuccess({
        total: notificationData.notifications.total,
        notificationreports: notificationData.notifications.notification.map((item) => ({
          ...item,
          key: item.notificationid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listNotificationError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listNotificationError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export default function* NotificationSaga() {
  yield takeLatest(LIST_NOTIFICATION, listNotificationSaga);
}
