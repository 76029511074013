import React, { useState, useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { addCategory, editCategory, activeCategory } from 'pages/Category/action';
import _ from 'lodash';
const { Option } = Select;

function AddCategoryDrawer({ initialState, selectedCategory, editProductCategory, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category || initialState);
  const [categoryList, setCategoryList] = useState([]);
  const [form] = Form.useForm();
  let cats = categoryState.data;

  if (!_.isNil(selectedCategory)) {
    form.setFieldsValue({
      productcatname: selectedCategory.childcategoryname,
      productcatdescription: selectedCategory.childcatdescription,
      parentcategoryid: selectedCategory.parentcategoryid,
      isenabled: _.isEqual(selectedCategory.isenabled, 1) ? true : false,
      ishomepagecat: selectedCategory?.ishomepagecat || false,
    });
  }

  useEffect(() => {
    if (categoryState.drawer) { dispatch(activeCategory()) }
  }, [categoryState.drawer]);

  useEffect(() => {
    if (_.isEmpty(categoryState.activeCategories)) {
      return;
    }
    if (!_.isNil(selectedCategory)) {
      const cats = categoryState.activeCategories.filter((cat) => !_.isEqual(cat.productcatid, selectedCategory.childcategoryid));
      setCategoryList(cats);
      return;
    }
    setCategoryList(categoryState.activeCategories);
  }, [categoryState.activeCategories]);

  const toggleCategoryDrawer = (open = false) => {
    form.resetFields();
    editProductCategory(null);
  };

  const handleCategory = () => {
    form.validateFields().then((values) => {
      if (_.isNil(values.isenabled) || values.isenabled) {
        values.isenabled = 1;
      } else {
        values.isenabled = 0;
      }
      if (selectedCategory) {
        const { childcategoryid } = selectedCategory;
        dispatch(editCategory(_.omitBy({ productcatid: childcategoryid, ...values }, _.isNil)));
      } else {
        dispatch(addCategory(_.omitBy(values, _.isNil)));
      }
    });
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleCategoryDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Add Category
        </Button>
      ) : (
        ''
      )}
      <Drawer
        title={selectedCategory ? `Edit Category` : `Add Category`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleCategoryDrawer()}
        visible={categoryState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => toggleCategoryDrawer()} loading={categoryState.loading} disabled={categoryState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleCategory()} loading={false} disabled={categoryState.loading} style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              {selectedCategory ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton loading={categoryState.loading} active={categoryState.loading}>
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productcatname"
                  label="Category Name"
                  rules={[{ required: true, message: 'Please enter category name' }]}
                >
                  <Input
                    placeholder="Please enter category name"
                    disabled={categoryState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productcatdescription"
                  label="Category Description"
                  rules={[{ required: true, message: 'Please enter category description' }]}
                >
                  <Input
                    placeholder="Please enter category description"
                    disabled={categoryState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isenabled" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="ishomepagecat" label="Homepage Category">
                  <Select placeholder="Homepage Category" defaultValue={false}>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="parentcategoryid"
                  label="Select Parent Category"
                  initialValue={selectedCategory?.parentcategoryid}
                  rules={[{ required: false, message: 'Please select parent category' }]}
                >
                  <Select placeholder="Select Category" loading={categoryState.loading}>
                    {categoryList.map((cat) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddCategoryDrawer);
