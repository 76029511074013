import * as Constant from './constant';

export const listContact = (payload) => {
  return {
    type: Constant.LIST_CONTACT,
    payload,
  };
};

export const listContactSuccess = (payload) => {
  return {
    type: Constant.LIST_CONTACT_SUCCESS,
    payload,
  };
};

export const listContactError = () => {
  return {
    type: Constant.LIST_CONTACT_ERROR,
  };
};
