import * as Constant from './constant';


export const listIncome = (payload) => {
  return {
    type: Constant.LIST_INCOME,
    payload,
  };
};

export const listIncomeSuccess = (payload) => {
  return {
    type: Constant.LIST_INCOME_SUCCESS,
    payload,
  };
};

export const listIncomeError = () => {
  return {
    type: Constant.LIST_INCOME_ERROR,
  };
};

