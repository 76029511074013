import React, { memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { editInvoiceSetting as editInvoiceSettingAction } from 'pages/InvoiceSettting/action';
import _ from 'lodash';

function AddInvoiceSettingDrawer({ initialState, selectedInvoiceSetting, editInvoiceSetting, invoiceSettingState, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  if (!_.isNil(selectedInvoiceSetting)) {
    let obj = {};
    if (selectedInvoiceSetting?.values.length > 0) {
      selectedInvoiceSetting.values.forEach((val) => {
        obj[val.genparam_id] = val.paramval;
      });
    }
    form.setFieldsValue(obj);
  }

  const toggleInvoiceSettingDrawer = (open = false) => {
    form.resetFields();
    editInvoiceSetting(null);
  };

  const handleInvoicesetting = () => {
    form.validateFields().then((values) => {
      const params = Object.keys(values).map((val) => ({
        genparam_id: val,
        paramval: values[val],
      }));
      dispatch(editInvoiceSettingAction({ params }));
      resetPagination();
    });
  };
  if (selectedInvoiceSetting?.values.length <= 0) {
    return null;
  }
  return (
    <div>
      <Drawer
        title={`Edit Invoice Setting`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleInvoiceSettingDrawer()}
        visible={invoiceSettingState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => toggleInvoiceSettingDrawer()} loading={invoiceSettingState.loading} disabled={invoiceSettingState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleInvoicesetting()} loading={false} disabled={invoiceSettingState.loading}
              style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              Edit
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" initialValues={{ remember: false }}>
          {selectedInvoiceSetting?.values.map((d) => (
            <Row>
              <Col span={24}>
                <Form.Item
                  name={d.genparam_id}
                  label={<Tooltip title={d.param_d}>{d.param_description}</Tooltip>}
                  rules={[{ required: true, message: `Please enter ${d.param_description}` }]}
                >
                  {_.isEqual(d.param_datatype, 'varchar') ? (
                    <Input
                      placeholder={`Please enter ${d.param_description}`}
                      disabled={invoiceSettingState.loading}
                    />
                  ) : (
                    <Input
                      type="number"
                      placeholder={`Please enter ${d.param_description}`}
                      disabled={invoiceSettingState.loading}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
      </Drawer>
    </div>
  );
}

export default memo(AddInvoiceSettingDrawer);
