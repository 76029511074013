import { call, put, takeEvery, takeLatest, apply } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listDiscount, addDiscount, editDiscount } from 'services/coupon';
import { ADD_DISCOUNT, EDIT_DISCOUNT, LIST_DISCOUNT, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listDiscount as listDiscountAction, listDiscountSuccess, listDiscountError, addDiscountSuccess, addDiscountError, editDiscountSuccess, editDiscountError, toggleDrawer, toggleDeleteModal } from './action';

export function* listDiscountSaga(action) {
  try {
    const res = yield call(listDiscount, action.payload);
    const discountData = JSON.parse(res.text);
    // console.log(discountData)
    yield put(
      listDiscountSuccess({
        total: discountData.discounts.total,
        attributes: discountData.discounts.discounts.map((item) => ({
          ...item,
          key: item.discountid,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listDiscountError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listDiscountError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* addDiscountSaga(action) {
  try {
    const res = yield call(addDiscount, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addDiscountSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listDiscountAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addDiscountError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addDiscountError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addDiscountError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editDiscountSaga(action) {
  try {
    const res = yield call(editDiscount, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editDiscountSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listDiscountAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editDiscountError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editDiscountError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editDiscountError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* DiscountSaga() {
  yield takeLatest(LIST_DISCOUNT, listDiscountSaga);
  yield takeEvery(ADD_DISCOUNT, addDiscountSaga);
  yield takeEvery(EDIT_DISCOUNT, editDiscountSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
