
import * as Constant from './constant';

export const addOrderCancel = (payload) => {
  return {
    type: Constant.ADD_ORDER_CANCEL,
    payload,
  };
};

export const addOrderCancelSuccess = () => {
  return {
    type: Constant.ADD_ORDER_CANCEL_SUCCESS,
  };
};

export const addOrderCancelError = () => {
  return {
    type: Constant.ADD_ORDER_CANCEL_ERROR,
  };
};

export const editOrderCancel = (payload) => {
  return {
    type: Constant.EDIT_ORDER_CANCEL,
    payload,
  };
};

export const editOrderCancelSuccess = () => {
  return {
    type: Constant.EDIT_ORDER_CANCEL_SUCCESS,
  };
};

export const editOrderCancelError = () => {
  return {
    type: Constant.EDIT_ORDER_CANCEL_ERROR,
  };
};

export const listOrderCancel = (payload) => {
  return {
    type: Constant.LIST_ORDER_CANCEL,
    payload,
  };
};

export const listOrderCancelSuccess = (payload) => {
  return {
    type: Constant.LIST_ORDER_CANCEL_SUCCESS,
    payload,
  };
};

export const listOrderCancelError = () => {
  return {
    type: Constant.LIST_ORDER_CANCEL_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
