import * as Constant from './constant';

export const addOrder = (payload) => {
  return {
    type: Constant.ADD_ORDER,
    payload,
  };
};

export const addOrderSuccess = () => {
  return {
    type: Constant.ADD_ORDER_SUCCESS,
  };
};

export const addOrderError = () => {
  return {
    type: Constant.ADD_ORDER_ERROR,
  };
};

export const markOrder = (payload) => {
  return {
    type: Constant.MARK_ORDER,
    payload,
  };
};

export const markOrderSuccess = () => {
  return {
    type: Constant.MARK_ORDER_SUCCESS,
  };
};

export const markOrderError = () => {
  return {
    type: Constant.MARK_ORDER_ERROR,
  };
};
export const listOrder = (payload) => {
  return {
    type: Constant.LIST_ORDER,
    payload,
  };
};

export const listOrderSuccess = (payload) => {
  return {
    type: Constant.LIST_ORDER_SUCCESS,
    payload,
  };
};

export const listOrderError = () => {
  return {
    type: Constant.LIST_ORDER_ERROR,
  };
};

export const drawerData = (payload) => {
  return {
    type: Constant.DRAWER_DATA,
    payload,
  };
};

export const drawerDataSuccess = (payload) => {
  return {
    type: Constant.DRAWER_DATA_SUCCESS,
    payload,
  };
};

export const drawerDataError = () => {
  return {
    type: Constant.DRAWER_DATA_ERROR,
  };
};

export const categoryAddon = (payload) => {
  return {
    type: Constant.CATEGORY_ADDON,
    payload,
  };
};

export const categoryAddonSuccess = (payload) => {
  return {
    type: Constant.CATEGORY_ADDON_SUCCESS,
    payload,
  };
};

export const categoryAddonError = () => {
  return {
    type: Constant.CATEGORY_ADDON_ERROR,
  };
};
export const activeAllOrder = () => {
  return {
    type: Constant.ACTIVE_ALL_ORDER,
  };
};

export const activeAllOrderSuccess = (payload) => {
  return {
    type: Constant.ACTIVE_ALL_ORDER_SUCCESS,
    payload,
  };
};

export const activeAllOrderError = () => {
  return {
    type: Constant.ACTIVE_ALL_ORDER_ERROR,
  };
};
export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};

export const addToCart = (payload) => {
  return {
    type: Constant.ADD_TO_CART,
    payload,
  };
};

export const removeFromCart = (payload) => {
  return {
    type: Constant.REMOVE_FROM_CART,
    payload,
  };
};

export const addAddonToCart = (payload) => {
  return {
    type: Constant.ADD_ADDON_TO_CART,
    payload,
  };
};

export const removeAddonFromCart = (payload) => {
  return {
    type: Constant.REMOVE_ADDON_FROM_CART,
    payload,
  };
};

export const clearCartList = () => {
  return {
    type: Constant.CLEAR_CART_LIST,
  };

  
};
