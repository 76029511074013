import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import categorySaga from 'pages/Category/saga';
import categoryReducer, { initialState as categoryInitialState } from 'pages/Category/reducer';
import { listEmail, toggleDrawer, toggleDeleteModal } from './action';
import clientReducer, { initialState as clientInitialState } from 'pages/Client/reducer';
import clientSaga from 'pages/Client/saga';
import { EmailTableConfig } from './EmailTableConfig';
import AddAddonEmailDrawer from 'components/AddAddonEmailDrawer';
import orderReducer, { initialState as orderInitialState } from 'pages/Orders/reducer';
import orderSaga from 'pages/Orders/saga';
function AddEmail() {
  useInjectReducer({ key: 'email', reducer });
  useInjectSaga({ key: 'email', saga });
  useInjectReducer({ key: 'category', reducer: categoryReducer });
  useInjectSaga({ key: 'category', saga: categorySaga });
  useInjectReducer({ key: 'client', reducer: clientReducer });
  useInjectSaga({ key: 'client', saga: clientSaga });
  useInjectReducer({ key: 'order', reducer: orderReducer });
  useInjectSaga({ key: 'order', saga: orderSaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  // const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedEmail, setselectedEmail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const emailState = useSelector((state) => state.email || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = EmailTableConfig(
      handleSearch,
      handleReset,
      editUserEmail,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listEmail({ page: 1, limit: 10 }));
  }, []);

  const resetPagination = () => setCurrentPage(1);

  const editUserEmail = (email) => {
    setselectedEmail(email);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listEmail(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listEmail({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };
  // const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listEmail({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Manage Email Addon"
        extra={
          <AddAddonEmailDrawer
            initialState={initialState}
            categoryInitialState={categoryInitialState}
            clientInitialState={clientInitialState}
            selectedEmail={selectedEmail}
            orderInitialState={orderInitialState}
            resetPagination={resetPagination}
            editUserEmail={editUserEmail}
            roleAddResponsibility={_.includes(authState.userResponsibilities, 'ADD_EMAIL_ADDON')}
          />
        }
      >
        <Table
          columns={columns}
          loading={emailState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={emailState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={emailState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={emailState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Portfolio Confirmation'}
        text={'Are you sure you want to delete this portfolio ?'}
        toggle={emailState.deleteModal}
        loading={emailState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default AddEmail;
