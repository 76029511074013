import { Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const OrderCartItemsTableConfig = ({ removeFromCart }) => {

  return [
    {
      title: 'Product ID',
      dataIndex: 'product_id',
      key: 'product_id',
      width: '10%',
    },
    {
      title: 'Product Name',
      dataIndex: 'productname',
      key: 'productname',
      width: '10%',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
    },
    {
      title: 'Unit Price',
      dataIndex: 'startingprice',
      key: 'startingprice',
      width: '10%',
    },
    {
      title: 'Custom Price',
      dataIndex: 'customprice',
      key: 'customprice',
      width: '10%',
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: '2%',
      render: (item) => {
        return (
          <div>
            <Space size="middle">
              <DeleteOutlined style={{ color: '#F52A0E', cursor: 'pointer', fontSize: '20px' }} onClick={() => removeFromCart(item)} />
            </Space>
          </div>
        );
      },
    },
  ];
};
