import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import AddPortfolioDrawer from 'components/AddPortfolioDrawer/Index';
import categorySaga from 'pages/Category/saga';
import categoryReducer, { initialState as categoryInitialState } from 'pages/Category/reducer';
import { PortfolioTableConfig } from './PortfolioTableConfig';
import { listPortfolio, toggleDrawer, toggleDeleteModal } from './action';

function Portfolio() {
  useInjectReducer({ key: 'portfolio', reducer });
  useInjectSaga({ key: 'portfolio', saga });
  useInjectReducer({ key: 'category', reducer: categoryReducer });
  useInjectSaga({ key: 'category', saga: categorySaga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedPortfolio, setselectedPortfolio] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const portfolioState = useSelector((state) => state.portfolio || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = PortfolioTableConfig(
      handleSearch,
      handleReset,
      editUserPortfolio,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listPortfolio({ page: 1, limit: 10 }));
  }, []);

  const resetPagination = () => setCurrentPage(1);

  const editUserPortfolio = (portfolio) => {
    setselectedPortfolio(portfolio);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    let filter = { page, limit: pageSize };
    setCurrentPage(page);
    if ((searchedColumn, searchText)) {
      filter = { ...filter, [searchedColumn]: searchText };
    }
    dispatch(listPortfolio(filter));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys.length <= 0) {
      message.error('Please select to search');
      return;
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(listPortfolio({ page: 1, limit: 10, [dataIndex]: selectedKeys[0] }));
    resetPagination();
  };
  // const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(null);
    setSearchedColumn(null);
    dispatch(listPortfolio({ page: 1, limit: 10 }));
    resetPagination();
  };
  return (
    <>
      <Card
        title="Manage Portfolio"
        extra={
          <AddPortfolioDrawer
            initialState={initialState}
            categoryInitialState={categoryInitialState}
            selectedPortfolio={selectedPortfolio}
            resetPagination={resetPagination}
            editUserPortfolio={editUserPortfolio}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'PRODUCT_PORTFOLIO_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={portfolioState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={portfolioState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={portfolioState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={portfolioState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Portfolio Confirmation'}
        text={'Are you sure you want to delete this portfolio ?'}
        toggle={portfolioState.deleteModal}
        loading={portfolioState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default Portfolio;
