import * as Constant from './constant';

export const addSubscription = (payload) => {
  return {
    type: Constant.ADD_SUBSCRIPTION,
    payload,
  };
};

export const addSubscriptionSuccess = () => {
  return {
    type: Constant.ADD_SUBSCRIPTION_SUCCESS,
  };
};

export const addSubscriptionError = () => {
  return {
    type: Constant.ADD_SUBSCRIPTION_ERROR,
  };
};

export const editSubscription = (payload) => {
  return {
    type: Constant.EDIT_SUBSCRIPTION,
    payload,
  };
};

export const editSubscriptionSuccess = () => {
  return {
    type: Constant.EDIT_SUBSCRIPTION_SUCCESS,
  };
};

export const editSubscriptionError = () => {
  return {
    type: Constant.EDIT_SUBSCRIPTION_ERROR,
  };
};

export const listSubscription = (payload) => {
  return {
    type: Constant.LIST_SUBSCRIPTION,
    payload,
  };
};

export const listSubscriptionSuccess = (payload) => {
  return {
    type: Constant.LIST_SUBSCRIPTION_SUCCESS,
    payload,
  };
};

export const listSubscriptionError = () => {
  return {
    type: Constant.LIST_SUBSCRIPTION_ERROR,
  };
};

export const deleteSubscription = () => {
  return {
    type: Constant.DELETE_SUBSCRIPTION,
  };
};

export const deleteSubscriptionSuccess = () => {
  return {
    type: Constant.DELETE_SUBSCRIPTION_SUCCESS,
  };
};

export const deleteSubscriptionError = () => {
  return {
    type: Constant.DELETE_SUBSCRIPTION_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
