import * as Constant from './constant';


export const listLog = (payload) => {
  return {
    type: Constant.LIST_LOG,
    payload,
  };
};

export const listLogSuccess = (payload) => {
  return {
    type: Constant.LIST_LOG_SUCCESS,
    payload,
  };
};

export const listLogError = () => {
  return {
    type: Constant.LIST_LOG_ERROR,
  };
};

