import React, { useState, useEffect, memo } from 'react';
import { Table, Divider } from 'antd';
import { OrderCartItemsTableConfig } from './OrderCartItemsTableConfig';
import { OrderAddonCartTableConfig } from './OrderAddonCartTableConfig';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart as removeFromCartAction, removeAddonFromCart as removeAddonFromCartAction } from 'pages/Orders/action';
import _ from 'lodash';

function OrderCartItems({ orderState }) {
  const [columns, setColumns] = useState([]);
  const [addonColumns, setAddonColumns] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = OrderCartItemsTableConfig({ removeFromCart });
    setColumns(columns);
    const addoncol = OrderAddonCartTableConfig(removeAddonFromCart);
    setAddonColumns(addoncol);
  }, []);

  const removeFromCart = (item) => {
    dispatch(removeFromCartAction([item]));
  };

  const removeAddonFromCart = (item) => {
    dispatch(removeAddonFromCartAction(item));
  };

  return (
    <>
      <Divider>Product Cart</Divider>
      <Table
        columns={columns}
        loading={orderState.loading}
        bordered
        size={'small'}
        dataSource={orderState.cartList}
        style={{ margin: '10px' }}
      />
      {/* <Divider>Addon Cart</Divider>
      <Table
        columns={addonColumns}
        loading={orderState.loading}
        bordered
        size={'small'}
        dataSource={orderState.addonCartList}
        style={{ margin: '10px' }}
      /> */}
    </>
  );
}

export default OrderCartItems;
