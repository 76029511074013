import React, { useEffect, useState } from 'react';
import { Card, Table, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from './reducer';
import saga from './saga';
import _ from 'lodash';
import AddAttributeDrawer from 'components/AddAttributeDrawer';
import ConfirmModal from 'components/ConfirmModal';
import { AttributeTableConfig } from './AttributeTableConfig';
import { listAttribute, deleteAttribute, toggleDrawer, toggleDeleteModal } from './action';

function Attribute() {
  useInjectReducer({ key: 'attribute', reducer });
  useInjectSaga({ key: 'attribute', saga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const userState = useSelector((state) => state.user || initialState);
  const attributeState = useSelector((state) => state.attribute || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = AttributeTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editProductAttributes,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listAttribute({ page: 1, limit: 10 }));
  }, []);

  const onDelete = (item) => {
    // if (!_.isNil(item)) {
    //   setSelectedAttribute(item);
    //   dispatch(toggleDeleteModal());
    //   return;
    // }
    // dispatch(deleteRole(selectedAttribute.roleid));
  };

  const resetPagination = () => setCurrentPage(1);

  const handleDeleteModalToggle = () => dispatch(toggleDeleteModal());

  const editProductAttributes = (attribute) => {
    setSelectedAttribute(attribute);
    dispatch(toggleDrawer());
  };

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    dispatch(listAttribute({ page, limit: pageSize }));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  return (
    <>
      <Card
        title="Product Attributes Configuration"
        extra={
          <AddAttributeDrawer
            initialState={initialState}
            selectedAttribute={selectedAttribute}
            resetPagination={resetPagination}
            editProductAttributes={editProductAttributes}
            roleAddResponsibility={_.includes(
              authState.userResponsibilities,
              'PRODUCT_ATTRIBUTE_ADD_BUTTON',
            )}
          />
        }
      >
        <Table
          columns={columns}
          loading={attributeState.loading}
          bordered
          pagination={false}
          size={'small'}
          dataSource={attributeState.data}
          style={{ height: '70vh', overflow: 'scroll' }}
        />
        <Pagination
          style={{ marginTop: '10px' }}
          total={attributeState.totalCount}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={10}
          current={currentPage}
          showLessItems={true}
          showSizeChanger={false}
          disabled={userState.loading}
          onChange={onPageChange}
        />
      </Card>
      {/* <ConfirmModal
        title={'Delete Attribute Confirmation'}
        text={'Are you sure you want to delete this attribute ?'}
        toggle={roleState.deleteModal}
        loading={roleState.loading}
        handleDeleteModalToggle={handleDeleteModalToggle}
        onDelete={onDelete}
      /> */}
    </>
  );
}

export default Attribute;
