import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { LIST_SERVER } from './constant';
import { listServer as listServerAction, listServerSuccess, listServerError } from './action';
import { listServer } from 'services/linode';

export function* listServerSaga(action) {
  try {
    const res = yield call(listServer, action.payload);
    const serverData = JSON.parse(res.text);
    // console.log(serverData)
    // console.log(res)
    yield put(
      listServerSuccess({
        total: serverData.servers.total,
        serverdetails: serverData.servers.servers.map((item) => ({
          ...item,
          key: item.server_id,
        })),

      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listServerError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listServerError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export default function* ServerSaga() {
  yield takeLatest(LIST_SERVER, listServerSaga);
}
