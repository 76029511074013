
export const ADD_EMAIL = 'ADD_EMAIL';
export const ADD_EMAIL_SUCCESS = 'ADD_EMAIL_SUCCESS';
export const ADD_EMAIL_ERROR = 'ADD_EMAIL_ERROR';

export const EDIT_EMAIL = 'EDIT_EMAIL';
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS';
export const EDIT_EMAIL_ERROR = 'EDIT_EMAIL_ERROR';

export const LIST_EMAIL = 'LIST_EMAIL';
export const LIST_EMAIL_SUCCESS = 'LIST_EMAIL_SUCCESS';
export const LIST_EMAIL_ERROR = 'LIST_EMAIL_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


