import * as Constant from './constant';

export const listServer = (payload) => {
  return {
    type: Constant.LIST_SERVER,
    payload,
  };
};

export const listServerSuccess = (payload) => {
  return {
    type: Constant.LIST_SERVER_SUCCESS,
    payload,
  };
};

export const listServerError = () => {
  return {
    type: Constant.LIST_SERVER_ERROR,
  };
};

