import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listTestimonial, addTestimonial, editTestimonial } from 'services/testimonial';
import { ADD_TESTIMONIAL, EDIT_TESTIMONIAL, LIST_TESTIMONIAL, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listTestimonial as listTestimonialAction, listTestimonialSuccess, listTestimonialError, addTestimonialSuccess, addTestimonialError, editTestimonialSuccess, editTestimonialError, toggleDrawer, toggleDeleteModal } from './action';

export function* listTestimonialSaga(action) {
  try {
    const res = yield call(listTestimonial, action.payload);
    const testimonialData = JSON.parse(res.text);
    yield put(
      listTestimonialSuccess({
        total: testimonialData.testimonials.total,
        attributes: testimonialData.testimonials.testimonials.map((item) => ({
          ...item,
          key: item.testimonial_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listTestimonialError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listTestimonialError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export function* addTestimonialSaga(action) {
  try {
    const res = yield call(addTestimonial, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addTestimonialSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listTestimonialAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addTestimonialError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addTestimonialError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addTestimonialError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editTestimonialSaga(action) {
  try {
    const res = yield call(editTestimonial, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editTestimonialSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listTestimonialAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editTestimonialError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editTestimonialError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editTestimonialError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}

export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* TestimonialSaga() {
  yield takeLatest(LIST_TESTIMONIAL, listTestimonialSaga);
  yield takeEvery(ADD_TESTIMONIAL, addTestimonialSaga);
  yield takeEvery(EDIT_TESTIMONIAL, editTestimonialSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
