import * as Constant from './constant';


export const listNotification = (payload) => {
  return {
    type: Constant.LIST_NOTIFICATION,
    payload,
  };
};

export const listNotificationSuccess = (payload) => {
  return {
    type: Constant.LIST_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const listNotificationError = () => {
  return {
    type: Constant.LIST_NOTIFICATION_ERROR,
  };
};

