import * as Constant from './constant';

export const addInvoice = (payload) => {
  return {
    type: Constant.ADD_INVOICE,
    payload,
  };
};

export const addInvoiceSuccess = () => {
  return {
    type: Constant.ADD_INVOICE_SUCCESS,
  };
};

export const addInvoiceError = () => {
  return {
    type: Constant.ADD_INVOICE_ERROR,
  };
};

export const invoiceDetail = (payload) => {
  return {
    type: Constant.INVOICE_DETAIL,
    payload,
  };
};

export const invoiceDetailSuccess = (payload) => {
  return {
    type: Constant.INVOICE_DETAIL_SUCCESS,
    payload,
  };
};

export const invoiceDetailError = () => {
  return {
    type: Constant.INVOICE_DETAIL_ERROR,
  };
};


export const listInvoice = (payload) => {
  return {
    type: Constant.LIST_INVOICE,
    payload,
  };
};

export const editInvoice = (payload) => {
  return {
    type: Constant.EDIT_INVOICE,
    payload,
  };
};

export const editInvoiceSuccess = () => {
  return {
    type: Constant.EDIT_INVOICE_SUCCESS,
  };
};

export const editInvooiceError = () => {
  return {
    type: Constant.EDIT_INVOICE_ERROR,
  };
};

export const listInvoiceSuccess = (payload) => {
  return {
    type: Constant.LIST_INVOICE_SUCCESS,
    payload,
  };
};

export const listInvoiceError = () => {
  return {
    type: Constant.LIST_INVOICE_ERROR,
  };
};


export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
