import React, { useState, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Collapse, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ClientUser from 'components/ClientUser';
import { addClient, editClient as editClientAction, addClientUser, editClientUser as editClientUserAction } from 'pages/Client/action';
import _ from 'lodash';
const { Option } = Select;
const { Panel } = Collapse;

function AddClientDrawer({ initialState, selectedClient, editClient, roleAddResponsibility, resetPagination }) {
  const dispatch = useDispatch();
  const [selectedClientUser, setSelectedClientUser] = useState(null);
  const clientState = useSelector((state) => state.client || initialState);
  const [form] = Form.useForm();
  const [clientForm] = Form.useForm();
  clientForm.resetFields();
  if (!_.isNil(selectedClient)) {
    form.setFieldsValue({
      client_name: selectedClient.client_name,
      status: _.isEqual(selectedClient.status, 1) ? true : false,
    });
  }

  const onChange = (key) => {
    console.log(key);
  };

  const selectClientUser = (user) => {
    if (!user) {
      clientForm.resetFields();
      setSelectedClientUser(null);
      return;
    }
    setSelectedClientUser(user);
    clientForm.setFieldsValue({
      firstname: user.firstname,
      lastname: user.lastname,
      mobilephone: user.mobilephone,
      clientemail: user.clientemail,
      is_master: user.is_master,
      is_active: user.is_active,
    });
  };

  const toggleClientDrawer = (open = false) => {
    form.resetFields();
    editClient(null);
  };

  const addEditClientForm = () => {
    form.validateFields().then((values) => {
      const status = _.isNil(values.status) ? true : values.status;
      if (selectedClient) {
        dispatch(
          editClientAction({
            ...values,
            client_id: selectedClient.client_id,
            status: status ? 1 : 0,
          }),
        );
      } else {
        dispatch(addClient({ ...values, status: status ? 1 : 0 }));
      }
      setSelectedClientUser(null);
      resetPagination();
    });
  };

  const clearClientForm = () => {
    clientForm.resetFields();
    setSelectedClientUser(null);
  };

  const addEditClientUserForm = () => {
    if (!selectedClient) {
      message.error('Please Select Client.');
      return;
    }
    const hasMasterUser = clientState.clientUser.filter((cu) => cu.is_master === true);

    clientForm.validateFields().then((values) => {
      const is_master = _.isNil(values.is_master) ? false : values.is_master;
      const is_active = _.isNil(values.is_active) ? false : values.is_active;
      if (is_master && hasMasterUser.length > 0 && hasMasterUser[0].email !== values.email) {
        message.error('Master User Already Exists');
        return;
      }
      if (selectedClientUser) {
        dispatch(
          editClientUserAction({
            ...values,
            is_master,
            is_active,
            clientuserid: selectedClientUser.clientuserid,
            client_id: selectedClientUser.client_id,
          }),
        );
      } else {
        dispatch(
          addClientUser({ ...values, is_master, is_active, client_id: selectedClient.client_id }),
        );
      }
    });
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button
          onClick={() => toggleClientDrawer()}
          size="medium"
          style={{
            backgroundColor: "#00916c",
            borderColor: "#00916c",
            color: "white",
          }}
        >
          {selectedClient ? 'Edit' : 'Add'} Client
        </Button>
      ) : (
        ''
      )}
      <Drawer
        title={selectedClient ? `Edit Client` : `Add Client`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleClientDrawer()}
        visible={clientState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Collapse defaultActiveKey={['1']} onChange={onChange} style={{ margin: '10px' }}>
          <Panel header="Client" key="1">
            <Form form={form} layout="vertical" initialValues={{ remember: false }}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="client_name"
                    label="Client Name"
                    rules={[{ required: true, message: 'Please enter client name' }]}
                  >
                    <Input placeholder="Please enter client name" disabled={clientState.loading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="status" label="Status">
                    <Select placeholder="Status" defaultValue={true}>
                      <Option value={true}>Active</Option>
                      <Option value={false}>Inactive</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  size="medium"
                  onClick={addEditClientForm}
                  loading={clientState.loading}
                  style={{ backgroundColor: '#32d193', borderColor: '#32d193', color: 'white' }}
                >
                  {selectedClient ? 'Edit' : 'Add'} Client
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Collapse
          defaultActiveKey={['1']}
          onChange={onChange}
          style={{ margin: '10px' }}
          collapsible="enabled"
        >
          <Panel header="Add Client User" key="2">
            <Form form={clientForm} layout="vertical" initialValues={{ remember: false }}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    rules={[{ required: true, message: 'Please enter first name' }]}
                  >
                    <Input placeholder="Please enter first name" disabled={clientState.loading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please enter last name' }]}
                  >
                    <Input placeholder="Please enter last name" disabled={clientState.loading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="mobilephone" label="Mobile Phone">
                    <Input
                      placeholder="Please enter mobile number"
                      disabled={clientState.loading}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="clientemail"
                    label="Client Email"
                    rules={[{ required: true, message: 'Please enter client email' }]}
                  >
                    <Input placeholder="Please enter client name" disabled={clientState.loading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="is_master" label="Master User">
                    <Select placeholder="Master" defaultValue={false}>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="is_active" label="Status">
                    <Select placeholder="Status" defaultValue={true}>
                      <Option value={true}>Active</Option>
                      <Option value={false}>Inactive</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Button
                  size="medium"
                  onClick={addEditClientUserForm}
                  loading={clientState.loading}
                  style={{
                    backgroundColor: "#00916c",
                    borderColor: "#00916c",
                    color: "white",
                  }}
                >
                  {selectedClientUser ? 'Edit' : 'Add'} User
                </Button>
                <Button
                  type="danger"
                  size="medium"
                  onClick={clearClientForm}
                  loading={clientState.loading}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <ClientUser
          clientState={clientState}
          selectedClient={selectedClient}
          selectClientUser={selectClientUser}
        />
      </Drawer>
    </div>
  );
}

export default memo(AddClientDrawer);
