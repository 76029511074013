
export const ADD_PORTFOLIO = 'ADD_PORTFOLIO';
export const ADD_PORTFOLIO_SUCCESS = 'ADD_PORTFOLIO_SUCCESS';
export const ADD_PORTFOLIO_ERROR = 'ADD_PORTFOLIO_ERROR';

export const EDIT_PORTFOLIO = 'EDIT_PORTFOLIO';
export const EDIT_PORTFOLIO_SUCCESS = 'EDIT_PORTFOLIO_SUCCESS';
export const EDIT_PORTFOLIO_ERROR = 'EDIT_PORTFOLIO_ERROR';

export const LIST_PORTFOLIO = 'LIST_PORTFOLIO';
export const LIST_PORTFOLIO_SUCCESS = 'LIST_PORTFOLIO_SUCCESS';
export const LIST_PORTFOLIO_ERROR = 'LIST_PORTFOLIO_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


