import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listServer = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/linode/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2)
    .query({ page })
    .query({ limit });
};

export const listShared = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/linode/getshared`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};
export const listDedicated = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/linode/getdedicated`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};
export const addDedicated = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/linode/create/dedicated`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editDedicated = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/linode/edit/dedicated`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const addShared = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/linode/create/shared`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editShared = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/linode/edit/shared`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
