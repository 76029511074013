import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';
import _ from "lodash"

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const listSatisfaction = async ({ limit, page, rate_id, isactive }) => {
  let satisfactionApi = superagent
    .get(`${BASE_URL}/satisfaction/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });

  if (rate_id) {
    satisfactionApi.query({ rate_id });
  }
  if (!_.isNil(isactive)) {
    satisfactionApi.query({ isactive });
  }
  return await satisfactionApi;
};


export const addSatisfaction = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/satisfaction/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
export const editSatisfaction = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/satisfaction/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
export const deleteSatisfaction = async (payload) => {
    return await superagent
      .delete(`${BASE_URL}/satisfaction/delete`)
      .set('Authorization', `Bearer ${getUserToken()}`)
      .set('Content-Type', 'application/json')
      .use(unauthorizedRedirect)
      .send(payload);
  };
  