import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
// import { ClientTableConfig } from './ClientTableConfig';
import { useSelector, useDispatch } from 'react-redux';
import reducer, { initialState } from 'pages/Client/reducer';
// import AddClientDrawer from 'components/AddClientDrawer';
import saga from 'pages/Client/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { listClient, toggleDrawer } from 'pages/Client/action';
import { UserTableConfig } from './UserTableConfig';
const GREY = '#9E9E9E';

function UserReport() {
  useInjectReducer({ key: 'client', reducer });
  useInjectSaga({ key: 'client', saga });
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalToggle, setDeleteModalToggle] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const clientState = useSelector((state) => state.client || initialState);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const columns = UserTableConfig(
      handleSearch,
      handleReset,
      onDelete,
      editClient,
      authState.userResponsibilities,
    );
    setColumns(columns);
    dispatch(listClient({ page: 1, limit: 10 }));
  }, []);

  const onDelete = (item) => {};

  const editClient = (client) => {
    setSelectedClient(client);
    dispatch(toggleDrawer());
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  return (
    <Card
      title="Our Clients"
      style={{
        borderRadius: '12px',
        width: '100%',
        height: '500px',
        backgroundColor: 'white',
        fontSize: 20,
        color: '#2a2a2a',
        boxShadow: `1px 3px 10px ${GREY}`,
        overflow: 'scroll',
      }}
      className="box"
    >
      <Table
        columns={columns}
        loading={clientState.loading}
        bordered
        pagination={false}
        size={'small'}
        style={{ height: '70vh' }}
        dataSource={clientState.data}
      />
    </Card>
  );
}

export default UserReport;
