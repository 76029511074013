import React, { memo, useEffect } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton, Divider, Table, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { SelectedAddonsConfig } from './SelectedAddonsConfig';
import _ from 'lodash';
import { addAddon, editAddon as editAddonAction, drawerData, addAddonAttribute as addAddonAttributeAction, removeAddonAttribute, clearAddonAttribute } from 'pages/Addons/action';
import { useState } from 'react';
const { Option } = Select;

function AddAddonDrawer({ initialState, loading, selectedAddon, editAddon, roleAddResponsibility, resetPagination, }) {
  const [form] = Form.useForm();
  const [addonForm] = Form.useForm();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(null);
  const addonState = useSelector((state) => state.addon || initialState);

  if (!_.isNil(selectedAddon)) {
    form.setFieldsValue({
      categoryid: selectedAddon.categoryid,
      addonname: selectedAddon.addonname,
      addondescription: selectedAddon.addondescription,
      ask_quantity: selectedAddon.ask_quantity,
      status: _.isEqual(selectedAddon.status, 1) ? true : false,
    });
  }

  useEffect(() => {
    const cols = SelectedAddonsConfig(removeAttribute);
    setColumns(cols);
  }, []);

  useEffect(() => {
    if (addonState.drawer) { dispatch(drawerData()) }
  }, [addonState.drawer]);

  useEffect(() => {
    if (selectedAddon) { dispatch(addAddonAttributeAction(selectedAddon.attributes)) }
  }, [selectedAddon]);

  const removeAttribute = (item) => {
    dispatch(removeAddonAttribute([item]));
  };

  const addAddonAttribute = () => {
    addonForm.validateFields().then((values) => {
      if (_.isEmpty(addonState.drawerData.attributes)) {
        return;
      }

      const attributeCheck = addonState.selectedAddons.filter((ad) => ad.attributeid === values.attributeid);

      if (attributeCheck.length > 0) {
        message.error('Attribute Already Selected')
        return;
      }

      const price = parseInt(values.unit_price);

      if (isNaN(price) || price < 0) {
        message.error('Please Enter Valid Price');
        return;
      }

      const attribute = addonState.drawerData.attributes.filter((ad) => ad.attributeid === values.attributeid);
      attribute[0].unit_price = values.unit_price;
      dispatch(addAddonAttributeAction(attribute));
    });
  };

  const toggleAddonDrawer = (open = false) => {
    form.resetFields();
    addonForm.resetFields();
    dispatch(clearAddonAttribute());
    editAddon(null);
  };

  const handleAddon = () => {
    form.validateFields().then((values) => {
      if (_.isEmpty(addonState.selectedAddons)) {
        message.error('Please Attach Attributes');
        return;
      }
      values.status = values.status ? 1 : 0;
      if (selectedAddon) {
        const payload = {
          addonid: selectedAddon.addonid, ...values, attributes: addonState.selectedAddons.map((sd) => ({ attributeid: sd.attributeid, unit_price: sd.unit_price }))
        };
        dispatch(editAddonAction(payload));
      } else {
        const payload = { ...values, attributes: addonState.selectedAddons.map((sd) => ({ attributeid: sd.attributeid, unit_price: sd.unit_price })) };
        dispatch(addAddon(payload));
      }
      resetPagination();
    });
  };

  const categories = Object.keys(addonState.drawerData).length > 0 ? addonState.drawerData.categories : [];
  const attributes = Object.keys(addonState.drawerData).length > 0 ? addonState.drawerData.attributes : [];

  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleAddonDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Add Addon
        </Button>
      ) : (
        ''
      )}
      <Drawer
        title={selectedAddon ? `Edit Addon` : `Add Addon`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleAddonDrawer()}
        visible={addonState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => toggleAddonDrawer()} loading={addonState.loading} disabled={addonState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button loading={false} disabled={addonState.loading} onClick={() => handleAddon()} style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              {selectedAddon ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton loading={addonState.drawerLoading} active={addonState.drawerLoading}>
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="categoryid"
                  label="Select Addon Category"
                  initialValue={selectedAddon?.categoryid}
                  rules={[{ required: true, message: 'Please select product category' }]}
                >
                  <Select placeholder="Select Category" loading={loading}>
                    {categories.map((cat, index) => (
                      <Option value={cat.productcatid} key={cat.productcatid}>
                        {/* <Option value={cat.productcatid} key={cat.productcatid} key={index}> */}
                        {cat.productcatname}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="addonname"
                  label="Addon Name"
                  rules={[{ required: true, message: 'Please enter Addon name' }]}
                >
                  <Input placeholder="Please enter addon name" disabled={addonState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="addondescription"
                  label="Addon Description"
                  rules={[{ required: false, message: 'Please enter addon description' }]}
                >
                  <Input.TextArea
                    placeholder="Please enter addon description"
                    rows={4}
                    allowClear={true}
                    disabled={addonState.loading}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="ask_quantity"
                  label="Ask Quantity"
                  rules={[{ required: true, message: 'Ask Quantity' }]}
                >
                  <Select placeholder="Ask Quantity" loading={loading}>
                    <Option value={false}>No</Option>
                    <Option value={true}>Yes</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="status"
                  label="Status"
                  initialValue={selectedAddon?.status}
                  rules={[{ required: true, message: 'Status' }]}
                >
                  <Select placeholder="Status" loading={loading}>
                    <Option value={false}>No</Option>
                    <Option value={true}>Yes</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider>Addon Attributes</Divider>
          <Form form={addonForm} layout="vertical" initialValues={{ remember: false }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="attributeid"
                  label="Attribute Selection"
                  rules={[{ required: true, message: 'Please select attribute' }]}
                >
                  <Select placeholder="Select Attribute" loading={loading}>
                    {attributes.map((attr, index) => (
                      <Option value={attr.attributeid} key={attr.attributeid}>
                        {attr.attributename}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="unit_price"
                  label="Unit Price"
                  rules={[{ required: true, message: 'Enter unit price' }]}
                >
                  <Input type="number" placeholder="Please enter Price" disabled={loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={4}>
                <Button style={{ backgroundColor: '#32d193', width: '100%', borderColor: '#32d193', color: 'white' }} onClick={addAddonAttribute}>
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          <Table
            columns={columns}
            loading={addonState.loading}
            bordered
            pagination={false}
            size={'small'}
            dataSource={addonState.selectedAddons}
            style={{ height: '70vh', overflow: 'scroll' }}
          />
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddAddonDrawer);
