export const ADD_ADDON = 'ADD_ADDON';
export const ADD_ADDON_SUCCESS = 'ADD_ADDON_SUCCESS';
export const ADD_ADDON_ERROR = 'ADD_ADDON_ERROR';

export const EDIT_ADDON = 'EDIT_ADDON';
export const EDIT_ADDON_SUCCESS = 'EDIT_ADDON_SUCCESS';
export const EDIT_ADDON_ERROR = 'EDIT_ADDON_ERROR';

export const LIST_ADDON = 'LIST_ADDON';
export const LIST_ADDON_SUCCESS = 'LIST_ADDON_SUCCESS';
export const LIST_ADDON_ERROR = 'LIST_ADDON_ERROR';

export const LIST_ADDON_CATEGORY = 'LIST_ADDON_CATEGORY';
export const LIST_ADDON_CATEGORY_SUCCESS = 'LIST_ADDON_CATEGORY_SUCCESS';
export const LIST_ADDON_CATEGORY_ERROR = 'LIST_ADDON_CATEGORY_ERROR';

export const ADD_ADDON_ATTRIBUTE = 'ADD_ADDON_ATTRIBUTE';
export const REMOVE_ADDON_ATTRIBUTE = 'REMOVE_ADDON_ATTRIBUTE';
export const CLEAR_ADDON_ATTRIBUTE = 'CLEAR_ADDON_ATTRIBUTE';

export const DRAWER_DATA = 'DRAWER_DATA';
export const DRAWER_DATA_SUCCESS = 'DRAWER_DATA_SUCCESS';
export const DRAWER_DATA_ERROR = 'DRAWER_DATA_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
