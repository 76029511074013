import { call, put, takeEvery, takeLatest, apply } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listPortfolio, addPortfolio, editPortfolio } from 'services/portfolio';
import { ADD_PORTFOLIO, EDIT_PORTFOLIO, LIST_PORTFOLIO, TOGGLE_DRAWER, TOGGLE_DELETE_MODAL } from './constant';
import { listPortfolio as listPortfolioAction, listPortfolioSuccess, listPortfolioError, addPortfolioSuccess, addPortfolioError, editPortfolioSuccess, editPortfolioError, toggleDrawer, toggleDeleteModal } from './action';

export function* listPortfolioSaga(action) {
  try {
    const res = yield call(listPortfolio, action.payload);
    const portfolioData = JSON.parse(res.text);
    // console.log(portfolioData)
    yield put(
      listPortfolioSuccess({
        total: portfolioData.portfolios.total,
        attributes: portfolioData.portfolios.portfolios.map((item) => ({
          ...item,
          key: item.portfolio_id,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listPortfolioError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listPortfolioError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}



export function* addPortfolioSaga(action) {
  try {
    const res = yield call(addPortfolio, action.payload);
    const userData = JSON.parse(res.text);
    yield put(addPortfolioSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listPortfolioAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(addPortfolioError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 400)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(addPortfolioError());
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(addPortfolioError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* editPortfolioSaga(action) {
  try {
    const res = yield call(editPortfolio, action.payload);
    const userData = JSON.parse(res.text);
    yield put(editPortfolioSuccess(userData.data));
    yield put(toggleDrawer());
    yield put(listPortfolioAction({ page: 1, limit: 10 }));
    message.success(userData.data.message);
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(editPortfolioError());
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(editPortfolioError());
    }
    if (_.isEqual(err.status, 409)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError.message);
      yield put(editPortfolioError());
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export function* toggleDrawerSaga(action) {
  put(toggleDrawer());
}
export function* toggleDeleteModalSaga(action) {
  put(toggleDeleteModal());
}

export default function* PortfolioSaga() {
  yield takeLatest(LIST_PORTFOLIO, listPortfolioSaga);
  yield takeEvery(ADD_PORTFOLIO, addPortfolioSaga);
  yield takeEvery(EDIT_PORTFOLIO, editPortfolioSaga);
  yield takeEvery(TOGGLE_DRAWER, toggleDrawerSaga);
  yield takeEvery(TOGGLE_DELETE_MODAL, toggleDeleteModalSaga);
}
