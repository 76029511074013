import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const CancelTableConfig = (handleSearch, handleReset) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                backgroundColor: '#32d193',
                borderColor: '#32d193',
                color: 'white',
              }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'Order ID',
      dataIndex: 'order_id',
      key: 'order_id',
      width: '3%',
    },
    {
        title: 'Product',
        dataIndex: 'productname',
        key: 'productname',
        width: '3%',
      },
      {
        title: 'Name',
        dataIndex: 'client_name',
        key: 'client_name',
        width: '3%',
      },
      {
        title: 'Subscription',
        dataIndex: 'subscriptionperiod',
        key: 'subscriptionperiod',
        width: '3%',
        render: (subscriptionperiod) => <>{subscriptionperiod} months</>
      },   
      {
        title: 'Credit',
        dataIndex: 'total_price',
        key: 'total_price',
        width: '3%',
      },  
      {
        title: 'Status',
        dataIndex: 'order_status',
        key: 'order_status',
        width: '3%',
      }, 
      
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: '3%',
        render: (created_at) => {
            return <div>{created_at ? created_at.split('T')[0] : 'N/A'}</div>;
          },
      },    
      
      
  ];
};
