import * as Constant from './constant';

export const initialState = { data: [], loading: false, error: false };

const incomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LIST_INCOME:
      return {
        ...state,
        loading: true,
        data: [],
        error: false,
      };
    case Constant.LIST_INCOME_SUCCESS:
      return {
        ...state,
        loading: false,
        // totalCount: action.payload.total,
        data: action.payload.incomereports,
        error: false,
      };
    case Constant.LIST_INCOME_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };


    default:
      return state;
  }
};

export default incomeReducer;
