
export const ADD_DNS = 'ADD_DNS';
export const ADD_DNS_SUCCESS = 'ADD_DNS_SUCCESS';
export const ADD_DNS_ERROR = 'ADD_DNS_ERROR';

export const EDIT_DNS = 'EDIT_DNS';
export const EDIT_DNS_SUCCESS = 'EDIT_DNS_SUCCESS';
export const EDIT_DNS_ERROR = 'EDIT_DNS_ERROR';

export const LIST_DNS = 'LIST_DNS';
export const LIST_DNS_SUCCESS = 'LIST_DNS_SUCCESS';
export const LIST_DNS_ERROR = 'LIST_DNS_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


