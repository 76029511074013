import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { listContact } from 'services/contact';
import { LIST_CONTACT } from './constant';
import { listContact as listContactAction, listContactSuccess, listContactError } from './action';

export function* listContactSaga(action) {
  try {
    const res = yield call(listContact, action.payload);
    const contactData = JSON.parse(res.text);
    // console.log(contactData);
    yield put(
      listContactSuccess({
        contacts: contactData.contacts.map((item) => ({
          ...item,
        })),
      }),
    );
  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listContactError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listContactError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}
export default function* ContactSaga() {
  yield takeLatest(LIST_CONTACT, listContactSaga);
}
