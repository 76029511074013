
export const ADD_PRODUCT_CONF = 'ADD_PRODUCT_CONF';
export const ADD_PRODUCT_CONF_SUCCESS = 'ADD_PRODUCT_CONF_SUCCESS';
export const ADD_PRODUCT_CONF_ERROR = 'ADD_PRODUCT_CONF_ERROR';

export const EDIT_PRODUCT_CONF = 'EDIT_PRODUCT_CONF';
export const EDIT_PRODUCT_CONF_SUCCESS = 'EDIT_PRODUCT_CONF_SUCCESS';
export const EDIT_PRODUCT_CONF_ERROR = 'EDIT_PRODUCT_CONF_ERROR';

export const LIST_PRODUCT_CONF = 'LIST_PRODUCT_CONF';
export const LIST_PRODUCT_CONF_SUCCESS = 'LIST_PRODUCT_CONF_SUCCESS';
export const LIST_PRODUCT_CONF_ERROR = 'LIST_PRODUCT_CONF_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';


