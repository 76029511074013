export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_ERROR = 'ADD_ORDER_ERROR';

export const MARK_ORDER = 'MARK_ORDER';
export const MARK_ORDER_SUCCESS = 'MARK_ORDER_SUCCESS';
export const MARK_ORDER_ERROR = 'MARK_ORDER_ERROR';

export const ACTIVE_ALL_ORDER = 'ACTIVE_ORDER';
export const ACTIVE_ALL_ORDER_SUCCESS = 'ACTIVE_ORDER_SUCCESS';
export const ACTIVE_ALL_ORDER_ERROR = 'ACTIVE_ORDER_ERROR';

export const LIST_ORDER = 'LIST_ORDER';
export const LIST_ORDER_SUCCESS = 'LIST_ORDER_SUCCESS';
export const LIST_ORDER_ERROR = 'LIST_ORDER_ERROR';

export const CATEGORY_ADDON = 'CATEGORY_ADDON';
export const CATEGORY_ADDON_SUCCESS = 'CATEGORY_ADDON_SUCCESS';
export const CATEGORY_ADDON_ERROR = 'CATEGORY_ADDON_ERROR';

export const DRAWER_DATA = 'DRAWER_DATA';
export const DRAWER_DATA_SUCCESS = 'DRAWER_DATA_SUCCESS';
export const DRAWER_DATA_ERROR = 'DRAWER_DATA_ERROR';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const ADD_ADDON_TO_CART = 'ADD_ADDON_TO_CART';
export const REMOVE_ADDON_FROM_CART = 'REMOVE_ADDON_FROM_CART';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';

export const CLEAR_CART_LIST = 'CLEAR_CART_LIST';
