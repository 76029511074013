import * as Constant from './constant';

export const addProduct = (payload) => {
  return {
    type: Constant.ADD_PRODUCT,
    payload,
  };
};

export const addProductSuccess = () => {
  return {
    type: Constant.ADD_PRODUCT_SUCCESS,
  };
};

export const addProductError = () => {
  return {
    type: Constant.ADD_PRODUCT_ERROR,
  };
};
export const activeAllProducts = () => {
  return {
    type: Constant.LIST_ACTIVE_PRODUCT,
  };
};

export const activeAllProductsSuccess = (payload) => {
  return {
    type: Constant.LIST_ACTIVE_PRODUCT_SUCCESS,
    payload,
  };
};

export const activeAllProductsError = () => {
  return {
    type: Constant.LIST_ACTIVE_PRODUCT_ERROR,
  };
};

export const editProduct = (payload) => {
  return {
    type: Constant.EDIT_PRODUCT,
    payload,
  };
};

export const editProductSuccess = () => {
  return {
    type: Constant.EDIT_PRODUCT_SUCCESS,
  };
};

export const editProductError = () => {
  return {
    type: Constant.EDIT_PRODUCT_ERROR,
  };
};

export const listProduct = (payload) => {
  return {
    type: Constant.LIST_PRODUCT,
    payload,
  };
};

export const listProductSuccess = (payload) => {
  return {
    type: Constant.LIST_PRODUCT_SUCCESS,
    payload,
  };
};

export const listProductError = () => {
  return {
    type: Constant.LIST_PRODUCT_ERROR,
  };
};

export const listProductAttribute = (payload) => {
  return {
    type: Constant.LIST_PRODUCT_ATTRIBUTE,
    payload,
  };
};

export const listProductAttributeSuccess = (payload) => {
  return {
    type: Constant.LIST_PRODUCT_ATTRIBUTE_SUCCESS,
    payload,
  };
};

export const listProductAttributeError = () => {
  return {
    type: Constant.LIST_PRODUCT_ATTRIBUTE_ERROR,
  };
};

export const selectedProductAttributes = (payload) => {
  return {
    type: Constant.SELECTED_PRODUCT_ATTRIBUTES,
    payload,
  };
};

export const removeSelectedProductAttributes = (payload) => {
  return {
    type: Constant.REMOVE_SELECTED_PRODUCT_ATTRIBUTES,
    payload,
  };
};

export const removeSelectedProductAttributesSuccess = (payload) => {
  return {
    type: Constant.REMOVE_SELECTED_PRODUCT_ATTRIBUTES_SUCCESS,
    payload,
  };
};

export const deleteProduct = () => {
  return {
    type: Constant.DELETE_PRODUCT,
  };
};

export const deleteProductSuccess = () => {
  return {
    type: Constant.DELETE_PRODUCT_SUCCESS,
  };
};

export const deleteProductError = () => {
  return {
    type: Constant.DELETE_PRODUCT_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
