import React, { useEffect } from 'react'
// import Statistics from 'color-statistics';
import { Button, Card, Col, Row } from 'antd';
import { BarChartOutlined, ShoppingOutlined, StopOutlined, UserAddOutlined } from '@ant-design/icons';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer, { initialState } from 'pages/Dashboard/reducer';
import saga from 'pages/Dashboard/saga';
import { useSelector, useDispatch } from 'react-redux';
import { clientState } from 'pages/Dashboard/action';
import { useHistory } from 'react-router-dom';

const GREY = '#9E9E9E';

function Statisticss() {
  useInjectReducer({ key: 'dashboard', reducer });
  useInjectSaga({ key: 'dashboard', saga });
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard || initialState);
  useEffect(() => {
    dispatch(clientState());
  }, []);
  const NewClient = () => {
    history.push("/clients");
  };
  const ProductSubscribe = () => {
    history.push("/orders");
  };
  return (
    <div>
      <Row style={{ padding: "10px" }} gutter={[16, 16]}>
        <Col xs={24} md={6} stylye={{ padding: "10px" }}>
          <Card
            style={{
              borderRadius: "12px",
              width: "100%",
              height: "170px",
              background: "transparent linear-gradient(119deg, #00C6FF 0%, #0072FF 100%) 0% 0% no-repeat padding-box",
              fontSize: 20,
              color: "white",
              boxShadow: `1px 3px 10px ${GREY}`,
              border: "none",
            }}
            className="box"
          >
            <strong className="net-ach">New Client</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", marginTop: "-42px", color: "#fff" }} className="icon-stat">
              {" "}
              <UserAddOutlined />{" "}
            </h1>
            <h1 className="stats-font" style={{ fontSize: "35px", color: "#fff", lineHeight: "50px" }}            >
              {dashboardState?.clientStats?.new_clients || 'Loading...'}
            </h1>

            <h6 style={{ display: "flex", justifyContent: "end" }}>
              <Button onClick={NewClient}>View</Button>
            </h6>
          </Card>
        </Col>

        <Col xs={24} md={6} stylye={{ padding: "10px" }}>
          <Card
            style={{
              borderRadius: "12px",
              width: "100%",
              height: "170px",
              background: "transparent linear-gradient(119deg, #FE8C00 0%, #F84400 100%) 0% 0% no-repeat padding-box",
              fontSize: 20,
              color: "#fff",
              border: "none",
              boxShadow: `1px 3px 10px ${GREY}`,
            }}
            className="box"
          >
            <strong className="net-ach"> Active Clients</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", marginTop: "-42px", color: "#fff" }} className="icon-stat">
              {" "}
              <BarChartOutlined />{" "}
            </h1>
            <h1 className="stats-font" style={{ fontSize: "35px", color: "#fff", lineHeight: "50px" }}            >
              {dashboardState?.clientStats?.new_clients || 'Loading...'}
            </h1>
            <h6 style={{ display: "flex", justifyContent: "end" }}>
              <Button className="num-2-btn" onClick={NewClient}>
                View
              </Button>
            </h6>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card
            style={{
              borderRadius: "12px",
              width: "100%",
              height: "170px",
              background: "transparent linear-gradient(119deg, #7EEF27 0%, #057A09 100%) 0% 0% no-repeat padding-box",
              fontSize: 20,
              color: "white",
              border: "none",
              boxShadow: `1px 3px 10px ${GREY}`,
            }}
            className="box"
          >
            <strong className="net-ach">Product Subscribe</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", marginTop: "-42px", color: "#fff" }} className="icon-stat">
              {" "}
              <ShoppingOutlined />{" "}
            </h1>
            <h1 className="stats-font" style={{ fontSize: "35px", color: "#fff", lineHeight: "50px" }}>
              {dashboardState?.clientStats?.products_subscribed || 'Loading...'}
            </h1>

            <h6 style={{ display: "flex", justifyContent: "end" }}>
              <Button className="num-2-btn" onClick={ProductSubscribe}>
                View
              </Button>
            </h6>
          </Card>
        </Col>
        <Col xs={24} md={6} stylye={{ padding: "10px" }}>
          <Card
            style={{
              borderRadius: "12px",
              width: "100%",
              height: "170px",
              background: "transparent linear-gradient(119deg, #ED213A 0%, #93291E 100%) 0% 0% no-repeat padding-box",
              fontSize: 20,
              color: "white",
              border: "none",
              boxShadow: `1px 3px 10px ${GREY}`,
            }}
            className="box"
          >
            <strong className="net-ach">Suspended Clients</strong>
            <br />

            <h1 style={{ float: "right", fontSize: "40px", marginTop: "-42px", color: "#fff" }} className="icon-stat">
              {" "}
              <StopOutlined />{" "}
            </h1>
            <h1 className="stats-font stats-font-2" style={{ fontSize: "35px", color: "#fff", lineHeight: "50px" }}>
              {dashboardState?.clientStats?.suspended_clients || 'Loading...'}
            </h1>
            <h6 style={{ display: "flex", justifyContent: "end" }}>
              <Button className="num-2-btn num-3-btn" onClick={ProductSubscribe}>
                View
              </Button>
            </h6>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Statisticss
