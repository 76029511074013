import { call, put, takeEvery, takeLatest, apply } from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import { authUserError } from 'pages/AppLogin/action';
import { LIST_INCOME } from './constant';
import { listIncome as listIncomeAction, listIncomeSuccess, listIncomeError } from './action';
// import { listUserLogs } from 'services/log';
import { monthlySalesCount } from 'services/invoice';

export function* listIncomeSaga(action) {
  try {
    const res = yield call(monthlySalesCount, action.payload);
    const incomeData = JSON.parse(res.text);
    // console.log(incomeData)
    yield put(
      listIncomeSuccess({
        // total: incomeData.logs.total,
        incomereports: incomeData.data.sales.map((item) => ({
          ...item,
          key: item.month,
        })),
      }),
      );
    //   console.log(listLogSuccess)

  } catch (err) {
    if (_.isEqual(err.status, 401)) {
      yield put(listIncomeError(err.response.text));
      yield put(authUserError({}));
    }
    if (_.isEqual(err.status, 422)) {
      const serverError = JSON.parse(err.response.text);
      message.error(serverError[0].message);
      yield put(listIncomeError(err.response.text));
    }
    if (_.isEqual(err.status, 500)) {
      message.error('Something went wrong');
    }
  }
}

export default function* IncomeSaga() {
  yield takeLatest(LIST_INCOME, listIncomeSaga);
}
