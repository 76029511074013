export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
export const ADD_SUBSCRIPTION_ERROR = 'ADD_SUBSCRIPTION_ERROR';

export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION';
export const EDIT_SUBSCRIPTION_SUCCESS = 'EDIT_SUBSCRIPTION_SUCCESS';
export const EDIT_SUBSCRIPTION_ERROR = 'EDIT_SUBSCRIPTION_ERROR';

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';

export const LIST_SUBSCRIPTION = 'LIST_SUBSCRIPTION';
export const LIST_SUBSCRIPTION_SUCCESS = 'LIST_SUBSCRIPTION_SUCCESS';
export const LIST_SUBSCRIPTION_ERROR = 'LIST_SUBSCRIPTION_ERROR';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DLEETE_MODAL';
