import * as Constant from './constant';

export const addDiscount = (payload) => {
  return {
    type: Constant.ADD_DISCOUNT,
    payload,
  };
};

export const addDiscountSuccess = () => {
  return {
    type: Constant.ADD_DISCOUNT_SUCCESS,
  };
};

export const addDiscountError = () => {
  return {
    type: Constant.ADD_DISCOUNT_ERROR,
  };
};

export const editDiscount = (payload) => {
  return {
    type: Constant.EDIT_DISCOUNT,
    payload,
  };
};

export const editDiscountSuccess = () => {
  return {
    type: Constant.EDIT_DISCOUNT_SUCCESS,
  };
};

export const editDiscountError = () => {
  return {
    type: Constant.EDIT_DISCOUNT_ERROR,
  };
};

export const listDiscount = (payload) => {
  return {
    type: Constant.LIST_DISCOUNT,
    payload,
  };
};

export const listDiscountSuccess = (payload) => {
  return {
    type: Constant.LIST_DISCOUNT_SUCCESS,
    payload,
  };
};

export const listDiscountError = () => {
  return {
    type: Constant.LIST_DISCOUNT_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
