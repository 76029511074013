import superagent from 'superagent';
import { getUserToken } from 'utils/authHeader';
import { unauthorizedRedirect } from 'utils/unauthorizedRedirect';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const addAddon = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/addon/create`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2)
    .send(payload);
};

export const listAddon = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/addon/list`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};

export const editAddon = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/addon/edit`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const deleteAddon = async (payload) => {
  return await superagent
    .delete(`${BASE_URL}/addon/delete`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload);
};

export const listAddonCategory = async () => {
  return await superagent
    .get(`${BASE_URL}/addon/category`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect);
};

export const getAddonDrawerData = async () => {
  return await superagent
    .get(`${BASE_URL}/miscellaneous/addon`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .retry(1);
};
export const addAddonSMTP = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/addon/createsmtp`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2)
    .send(payload);
};

export const listAddonSMTP = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/addon/getsmtp`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};

export const editAddonSMTP = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/addon/editsmtp`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const addAddonEmail = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/addon/createemailhosting`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .retry(2)
    .send(payload);
};

export const listAddonEmail = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/addon/getemailhosting`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};

export const editAddonEmail = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/addon/editemailhosting`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const listDNS = async ({ page, limit }) => {
  return await superagent
    .get(`${BASE_URL}/addon/getdns`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .query({ page })
    .query({ limit })
    .retry(2);
};

export const addDNS = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/addon/create/dns`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editDNS = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/addon/editdns`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
export const listProductConfig = async ({ limit, page }) => {
  return await superagent
    .get(`${BASE_URL}/product/listprodconfig`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .query({ page })
    .query({ limit });
};

export const addProductConfig = async (payload) => {
  return await superagent
    .post(`${BASE_URL}/product/config`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};

export const editProductConfig = async (payload) => {
  return await superagent
    .put(`${BASE_URL}/product/editconfig`)
    .set('Authorization', `Bearer ${getUserToken()}`)
    .set('Content-Type', 'application/json')
    .use(unauthorizedRedirect)
    .send(payload)
    .retry(2);
};
