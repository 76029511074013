import { Input, Button, Space, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const UserTableConfig = (handleSearch, handleReset) => {
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    };
  };
  return [
    {
      title: 'Client ID',
      dataIndex: 'client_id',
      key: 'client_id',
      width: '2%',
    },
    {
      title: 'Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: '3%',
      ...getColumnSearchProps('client_name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '3%',
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '5%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone No',
      dataIndex: 'mobile_phone',
      key: 'mobile_phone',
      width: '3%',
    },
    {
      title: 'Verified',
      dataIndex: 'email_verified',
      key: 'email_verified',
      width: '5%',
      render: (status) => {
        return (
          <Tag color={_.isEqual(status, 1) ? 'green' : 'red'}>
            {_.isEqual(status, 1) ? 'Active' : 'In Active'}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (status) => {
        return (
          <Tag color={_.isEqual(status, 1) ? 'green' : 'red'}>
            {_.isEqual(status, 1) ? 'Active' : 'In Active'}
          </Tag>
        );
      },
    },

  ];
};
