
import * as Constant from './constant';

export const addProductconf = (payload) => {
  return {
    type: Constant.ADD_PRODUCT_CONF,
    payload,
  };
};

export const addProductconfSuccess = () => {
  return {
    type: Constant.ADD_PRODUCT_CONF_SUCCESS,
  };
};

export const addProductconfError = () => {
  return {
    type: Constant.ADD_PRODUCT_CONF_ERROR,
  };
};

export const editProductconf = (payload) => {
  return {
    type: Constant.EDIT_PRODUCT_CONF,
    payload,
  };
};

export const editProductconfSuccess = () => {
  return {
    type: Constant.EDIT_PRODUCT_CONF_SUCCESS,
  };
};

export const editProductconfError = () => {
  return {
    type: Constant.EDIT_PRODUCT_CONF_ERROR,
  };
};

export const listProductconf = (payload) => {
  return {
    type: Constant.LIST_PRODUCT_CONF,
    payload,
  };
};

export const listProductconfSuccess = (payload) => {
  return {
    type: Constant.LIST_PRODUCT_CONF_SUCCESS,
    payload,
  };
};

export const listProductconfError = () => {
  return {
    type: Constant.LIST_PRODUCT_CONF_ERROR,
  };
};

export const toggleDrawer = () => {
  return {
    type: Constant.TOGGLE_DRAWER,
  };
};

export const toggleDeleteModal = () => {
  return {
    type: Constant.TOGGLE_DELETE_MODAL,
  };
};
