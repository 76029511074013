import React, { useEffect, memo } from 'react';
import { Input, Form, Button, Drawer, Col, Row, Select, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { activeAllProducts } from 'pages/Product/action';
import { activeAllClient } from 'pages/Client/action';
import { addShared, editShared } from 'pages/PurchasedProducts/SharedProduct/action';
import { activeAllOrder } from 'pages/Orders/action';
const { Option } = Select;

function AddSharedDrawer({ initialState, productInitialState, orderInitialState, selectedShared, clientInitialState, editUserShared, resetPagination, roleAddResponsibility }) {
  const dispatch = useDispatch();
  const sharedState = useSelector((state) => state.shared || initialState);
  const clientState = useSelector((state) => state.client || clientInitialState);
  const orderState = useSelector((state) => state.order || orderInitialState);
  const productState = useSelector((state) => state.product || productInitialState);
  const [form] = Form.useForm();

  const toggleSharedDrawer = (open = false) => {
    form.resetFields();
    editUserShared(null);
  };
  if (!_.isNil(selectedShared)) {
    form.setFieldsValue({
      productid: selectedShared.productid,
      order_id: selectedShared.order_id,
      clientid: selectedShared.clientid,
      isactive: selectedShared.isactive,
      username: selectedShared.username,
      password: selectedShared.password,
      cplan: selectedShared.cplan,
      cdomain: selectedShared.cdomain,
      cemail: selectedShared.cemail,
    });
  } else {
    form.setFieldsValue({
      isactive: true,
    });
  }

  useEffect(() => {
    if (sharedState.drawer) { dispatch(activeAllProducts()) }
  }, [productState.drawer]);

  useEffect(() => {
    if (sharedState.drawer) { dispatch(activeAllClient()) }
  }, [clientState.drawer]);

  useEffect(() => {
    if (sharedState.drawer) { dispatch(activeAllOrder()) }
  }, [orderState.drawer]);

  const handleShared = () => {
    form.validateFields().then((values) => {
      if (selectedShared) {
        const { shared_id } = selectedShared;
        dispatch(editShared({ shared_id, ...values }));
      } else {
        dispatch(addShared(values));
      }
    });

    resetPagination();
  };
  return (
    <div>
      {roleAddResponsibility ? (
        <Button onClick={() => toggleSharedDrawer()} size="medium" style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
          Add Shared
        </Button>
      ) : (
        ''
      )}

      <Drawer
        title={selectedShared ? `Edit` : `Add`}
        width={800}
        destroyOnClose={true}
        onClose={() => toggleSharedDrawer()}
        visible={sharedState.drawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => toggleSharedDrawer()} loading={sharedState.loading} disabled={sharedState.loading} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => handleShared()} loading={false} disabled={sharedState.loading} style={{ backgroundColor: "#00916c", borderColor: "#00916c", color: "white" }}>
              {selectedShared ? 'Edit' : 'Save'}
            </Button>
          </div>
        }
      >
        <Skeleton
          loading={orderState.loading && productState.loading && sharedState.loading && clientState.loading}
          active={orderState.loading && productState.loading && sharedState.loading && clientState.loading}
        >
          <Form form={form} layout="vertical" initialValues={{ remember: false }}>
            {/* <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Order ID"
                  rules={[{ required: false, message: 'Please enter order id' }]}
                >
                  <Input placeholder="Please enter order id" disabled={sharedState.loading} />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Form.Item
                  name="order_id"
                  label="Select Order"
                  initialValue={selectedShared?.order_id}
                  rules={[{ required: true, message: 'Please select order' }]}
                >
                  <Select placeholder="Select Order" loading={orderState.loading}>
                    {orderState.activeallOrders.map((cat) => (
                      <Option value={cat.order_id} key={cat.order_id}>
                        ({cat.order_id}) {cat.productname} ({cat.client_name})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="productid"
                  label="Select Product"
                  initialValue={selectedShared?.productid}
                  rules={[{ required: true, message: 'Please select Product' }]}
                >
                  <Select placeholder="Select Product" loading={productState.loading}>
                    {productState.activeallProducts.map((cat) => (
                      <Option value={cat.productid} key={cat.productid}>
                        {cat.productname} ({cat.productid})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="clientid"
                  label="Select Client"
                  initialValue={selectedShared?.client_id}
                  rules={[{ required: true, message: 'Please select client' }]}
                >
                  <Select placeholder="Select client" loading={clientState.loading}>
                    {clientState.activeallClients.map((cat) => (
                      <Option value={cat.client_id} key={cat.client_id}>
                        {cat.client_name} {cat.last_name} ({cat.client_id})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="username"
                  label="User Name"
                  rules={[{ required: true, message: 'Please enter User Name' }]}
                >
                  <Input placeholder="Please enter User Name" disabled={sharedState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: 'Please enter Password' }]}
                >
                  <Input placeholder="Please enter Password" disabled={sharedState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="cplan"
                  label="cplan"
                  rules={[{ required: true, message: 'Please enter Plan' }]}
                >
                  <Input placeholder="Please enter Plan" disabled={sharedState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="cdomain"
                  label="Domain"
                  rules={[{ required: true, message: 'Please enter Domain' }]}
                >
                  <Input placeholder="Please enter Domain" disabled={sharedState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="cemail"
                  label="Email"
                  rules={[{ required: true, message: 'Please enter Email' }]}
                >
                  <Input placeholder="Please enter Email" disabled={sharedState.loading} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="isactive" label="Status">
                  <Select placeholder="Status" defaultValue={true}>
                    <Option value={true}>Active</Option>
                    <Option value={false}>Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Drawer>
    </div>
  );
}

export default memo(AddSharedDrawer);
